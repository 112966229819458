import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './views/auth/Login';
import Register from './views/auth/Register';
import CHRPage from './views/gestionEstablissement/CHRPage';
import AdminPage from './views/adminEstablissement/AdminPage';
import ManageProductsPage from './views/adminEstablissement/ManageProductsPage';
import ManageSalesPage from './views/adminEstablissement/ManageSalesPage';
import EventManagement from './views/adminEstablissement/EventManagement';
import ManageContainerCardsPage from './views/hoali/ManageContainerCardsPage';
import StockManagementView from './views/hoali/StockManagementView';
import StockMugManagementView from './views/hoali/StockMugManagementView';
import CreateDrinkForm from './views/hoali/creation_boisson/CreateDrinkForm';
import CreateContainerComponent from './components/CreateContainerComponentEtablissement';
import ManageDrinkCategories from './components/ManageDrinkCategories';
import CategoryManager from './components/CreateCategoryComponent';
import AddEntreprisePage from './views/hoali/entreprise';
import ManageProductShop from './views/hoali/ManageShopProduct';
import DisplayProducts from './components/DisplayProducts';

const routes = [
  { path: "/", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/accueil", element: <CHRPage /> , protected: true},
  { path: "/admin/:id_etablissement", element: <AdminPage /> , protected: true},
  { path: "/admin/manage-products/:id_etablissement", element: <ManageProductsPage /> , protected: true},
  { path: "/admin/manage-sales/:id_etablissement", element: <ManageSalesPage /> , protected: true},
  { path: "/admin/manage-event/:id_etablissement", element: <EventManagement />, protected: true },
  { path: "/admin/manage-container/:establishmentId", element: <CreateContainerComponent />, protected: true },
  { path: "/admin/manage-shop/:establishmentId", element: <DisplayProducts />, protected: true },
  // { path: "/admin/create-categories/:establishmentId", element: <ManageDrinkCategories /> , protected: true },
  { path: "/admin/manage-container-cards", element: <ManageContainerCardsPage /> , protected: true, requireAdmin: true},
  { path: "/admin/manage-products-shop", element: <ManageProductShop /> , protected: true, requireAdmin: true},
  { path: "/admin/gestion_stocks", element: <StockManagementView /> , protected: true, requireAdmin: true },
  { path: "/admin/gestion_stocks_mug", element: <StockMugManagementView />  , protected: true, requireAdmin: true},
  { path: "/admin/create_drink", element: <CreateDrinkForm /> , protected: true, requireAdmin: true },
  { path: "/admin/create-categories/", element: <CategoryManager /> , protected: true , requireAdmin: true },
  { path: "/admin/entreprise/", element: <AddEntreprisePage /> , protected: true , requireAdmin: true },
 


];

export default routes;
