import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { TextField, Button, makeStyles, Typography } from '@material-ui/core';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

// Styles
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: "2rem"
    },
    title: {
        marginBottom: theme.spacing(2),
      },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '80%',
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    map: {
        height: "400px",
        width: "80%",
        marginBottom: theme.spacing(2),
    },
}));

let defaultIcon = new Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});


const EstablishmentLocation = ({ location, onLocationChange }) => {
    const [address, setAddress] = useState("");
    const [markerPosition, setMarkerPosition] = useState(location || [46.603354, 1.888334]); // Coordonnées par défaut
    const classes = useStyles(); // Ajout des styles
    let mapRef = null;



    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleAddressSubmit = async () => {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`);
        const data = await response.json();
        if (data.length > 0) {
            const { lat, lon } = data[0];
            setMarkerPosition([parseFloat(lat), parseFloat(lon)]);
            onLocationChange({ lat: parseFloat(lat), lon: parseFloat(lon) });
        } else {
            console.log("No results found");
        }
    };

    const updateMarker = (newPos) => {
       
        console.log(newPos)
        setMarkerPosition([parseFloat(newPos.lat), parseFloat(newPos.lng)]);
        onLocationChange({ lat: parseFloat(newPos.lat), lon: parseFloat(newPos.lng) });
    }

    const Markers = () => {
        const map = useMapEvents({
            click(e) {
                updateMarker(e.latlng);
            },
        });
        return (
            <Marker
                position={markerPosition}
                draggable={true}
                icon={defaultIcon}
                eventHandlers={{
                    dragend: (e) => {
                        updateMarker(e.target.getLatLng());
                    }
                }}
            />
        );
    }

    const handlePositionValidation = () => {
        // Implement the functionality for validating the position
        console.log(`Marker position is validated at ${markerPosition}`);

    };

    return (
        <div className={classes.root}>
            <Typography variant="h4" component="h1" className={classes.title}>
                Emplacement de l'établissement
            </Typography>

            <div className={classes.container}>

                <TextField
                    value={address}
                    onChange={handleAddressChange}
                    label="Adresse"
                    variant="outlined"
                    className={classes.textField}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddressSubmit}
                    className={classes.button}
                >
                    Déplacer le marqueur vers cette adresse
                </Button>
                {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={handlePositionValidation}
                    className={classes.button}
                >
                    Valider la position du marqueur
                </Button> */}
                <MapContainer className={classes.map} center={markerPosition} zoom={13}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Markers />
                </MapContainer>
            </div>
        </div>
    )
};

export default EstablishmentLocation;
