import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';
function GourdeOrderForm({ etabId, setRefresh }) {
    const [quantity, setQuantity] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');

    // useEffect(() => {
    //     // Récupération de la liste des fournisseurs
    //     axios.get('https://backoffice.gourdefriendly.green/stock_mug/suppliers')
    //         .then(response => {
    //             setSuppliers(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Erreur lors de la récupération des fournisseurs:', error);
    //         });
    // }, []);

    useEffect(() => {
        axiosInstance.get('/stock_mug/suppliers')
            .then(response => {
                setSuppliers(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des fournisseurs:', error);
            });
    }, []);

    const handleOrderSubmit = () => {
        if (!quantity || !selectedSupplier) {
            alert('Veuillez entrer une quantité valide et sélectionner un fournisseur.');
            return;
        }

      console.log(`https://backoffice.gourdefriendly.green/stock_mug/gourde-orders/${etabId}`)
      console.log({
        quantity_ordered: quantity,
            supplier_id: selectedSupplier
      })

        // axios.post(`https://backoffice.gourdefriendly.green/stock_mug/gourde-orders/${etabId}`, {
        //     quantity_ordered: quantity,
        //     supplier_id: selectedSupplier
        // })
        // .then(response => {

        //     setRefresh(true);
        // })
        // .catch(error => {

        //     console.error("Erreur lors de la création de la commande :", error);
        // });

        axiosInstance.post(`/stock_mug/gourde-orders/${etabId}`, {
            quantity_ordered: quantity,
            supplier_id: selectedSupplier
        })
        .then(response => {
            setRefresh(true);
        })
        .catch(error => {
            console.error("Erreur lors de la création de la commande :", error);
        });
    };

    return (
        <div className="gourde-order-form">
            <div>
                <label>Quantité :</label>
                <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} />
            </div>
            <div>
                <label>Fournisseur :</label>
                <select value={selectedSupplier} onChange={e => setSelectedSupplier(e.target.value)}>
                    <option value="">Sélectionnez un fournisseur</option>
                    {suppliers.map(supplier => (
                        <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                    ))}
                </select>
            </div>
            <button onClick={handleOrderSubmit}>Passer la commande</button>
        </div>
    );
}

export default GourdeOrderForm;
