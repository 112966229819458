import React, { useState } from 'react';
import './StockManagement.css'
import axiosInstance from '../../services/axiosInstance';
function ManageStock({ etabId ,  setRefresh }) {
    const [chrId, setChrId] = useState(etabId);
    const [quantity, setQuantity] = useState(0);
    const [type, setType] = useState('ADD'); // 'ADD' ou 'REMOVE'


    // const handleSubmit = () => {
       
    //     // Envoyez les données à l'API pour mettre à jour le stock
    //     // Par exemple, POST vers '/api/manage-stock'
        
    //     const data = { "quantity" : parseInt(quantity) , type  };

    //     console.log(data)
    //     fetch(`https://backoffice.gourdefriendly.green/stock_mug/inventory/transaction/${etabId}`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(data),
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         setRefresh(true)
    //     })
    //     .catch(error => {
    //         console.error("Il y a eu un problème avec l'opération fetch:", error);
    //     });
    // };

    const handleSubmit = () => {
        const data = { "quantity": parseInt(quantity), type };
    
        console.log(data);
    
        axiosInstance.post(`/stock_mug/inventory/transaction/${etabId}`, data)
            .then(response => {
                setRefresh(true);
            })
            .catch(error => {
                console.error("Il y a eu un problème avec l'opération fetch:", error);
            });
    };

    return (
        <div>
            {/* <h2>Gérer le stock</h2> */}
            <div>
                <label>CHR ID:</label>
                <input type="number" value={etabId}  />
            </div>
            <div>
                <label>Quantité:</label>
                <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} />
            </div>
            <div>
                <label>Type:</label>
                <select value={type} onChange={e => setType(e.target.value)}>
                    <option value="ADD">Ajouter</option>
                    <option value="REMOVE">Retirer</option>
                </select>
            </div>
            <button onClick={handleSubmit}>Mettre à jour le stock</button>
        </div>
    );
}

export default ManageStock;