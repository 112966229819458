import React from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';

function EtablissementAdd({ etablissementId }) {
    const handleAddToInventory = async () => {
    
        try {
            // const response = await axios.post('https://backoffice.gourdefriendly.green/stock/inventory', {
            //     etablissement_id: etablissementId
            // });
            const response = await axiosInstance.post('https://backoffice.gourdefriendly.green/stock/inventory', {
                etablissement_id: etablissementId
            });
            if (response.status === 201) {
            
                alert('Etablissement ajouté à GourdeInventory avec succès!');
            } else {
                alert('Erreur lors de l\'ajout à GourdeInventory.');
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout à GourdeInventory:", error);
            alert('Erreur lors de l\'ajout à GourdeInventory. 2' );
        }
    };

    return (
        <div>
            
            <button onClick={handleAddToInventory}>Ajouter à GourdeInventory</button>
        </div>
    );
}

export default EtablissementAdd;
