// import React from 'react';
// import { Navigate } from 'react-router-dom';

// function ProtectedRoute({ isAdmin, redirectTo, children }) {
//     return isAdmin ? children : <Navigate to={redirectTo} />;
//   }
  

// export default ProtectedRoute;

// import React from 'react';
// import { Navigate, Link } from 'react-router-dom';

// function AccessDeniedMessage() {
//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h2>Nous sommes désolés</h2>
//       <p>Vous n'avez pas accès à cette partie de l'application.</p>
//       <Link to="/">Retour à la page d'accueil</Link>
//     </div>
//   );
// }

import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, requireAdmin = false }) {
  const token = localStorage.getItem('token'); // Vérifiez que l'utilisateur est connecté
  const isAdmin = localStorage.getItem('admin') === 'true'; // Assurez-vous que 'admin' est stocké correctement



  if (!token || (requireAdmin && !isAdmin)) {
    return <Navigate to="/" />;
  }

  return children;
}

export default ProtectedRoute;


