import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UpdateSupplier from './UpdateSupplier';
import SupplierOrderHistory from './SupplierOrderHistory'; // N'oubliez pas d'importer le composant
import axiosInstance from '../../services/axiosInstance';
function SupplierList() {
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedSupplierForHistory, setSelectedSupplierForHistory] = useState(null);

    useEffect(() => {
        fetchSuppliers();
    }, []);

    // const fetchSuppliers = () => {
    //     axios.get('https://backoffice.gourdefriendly.green/stock_mug/suppliers')
    //         .then(response => {
    //             setSuppliers(response.data);
    //         });
    // }

    const fetchSuppliers = () => {
        axiosInstance.get('/stock_mug/suppliers')
            .then(response => {
                setSuppliers(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des fournisseurs:', error);
            });
    };

    const handleUpdateClick = (supplier) => {
        setSelectedSupplier(supplier);
    }

    // const handleDeleteClick = (supplierId) => {
    //     if (window.confirm('Êtes-vous sûr de vouloir supprimer ce fournisseur?')) {
    //         axios.delete(`https://backoffice.gourdefriendly.green/stock_mug/suppliers/${supplierId}`)
    //             .then(response => {
    //                 console.log('Fournisseur supprimé avec succès!', response.data);
    //                 fetchSuppliers();
    //             })
    //             .catch(error => {
    //                 console.error('Erreur lors de la suppression du fournisseur:', error);
    //             });
    //     }
    // }
    const handleDeleteClick = (supplierId) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce fournisseur?')) {
            axiosInstance.delete(`/stock_mug/suppliers/${supplierId}`)
                .then(response => {
                    console.log('Fournisseur supprimé avec succès!', response.data);
                    fetchSuppliers();
                })
                .catch(error => {
                    console.error('Erreur lors de la suppression du fournisseur:', error);
                });
        }
    };

    const handleHistoryClick = (supplier) => {
        setSelectedSupplierForHistory(supplier);
    }

    return (
        <div>
            {selectedSupplier ? (
                <UpdateSupplier
                    supplierId={selectedSupplier.id}
                    supplierDetails={selectedSupplier}
                    onSupplierUpdated={() => {
                        setSelectedSupplier(null);
                        fetchSuppliers();
                    }}
                    onSupplierDeleted={() => {
                        setSelectedSupplier(null);
                        fetchSuppliers();
                    }}
                />
            ) : selectedSupplierForHistory ? (
                <div>
                    <h3>Historique des commandes pour {selectedSupplierForHistory.name}</h3>
                    <SupplierOrderHistory supplierId={selectedSupplierForHistory.id} />
                    <button onClick={() => setSelectedSupplierForHistory(null)}>Retour à la liste</button>
                </div>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Nom du fournisseur</th>
                            <th>Détails du contact</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {suppliers.map(supplier => (
                            <tr key={supplier.id}>
                                <td>{supplier.name}</td>
                                <td>{supplier.contact_details}</td>
                                <td>
                                    <button onClick={() => handleUpdateClick(supplier)} style={{ marginRight: '10px' }}>Modifier</button>
                                    <button onClick={() => handleDeleteClick(supplier.id)} style={{ marginRight: '10px' }}>Supprimer</button>
                                    <button onClick={() => handleHistoryClick(supplier)}>Historique</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default SupplierList;
