import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import AdminNavbar from '../../components/AdminNavbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: "2rem"
    },
}));

// async function fetchEstablishment(id) {
//     try {
//         const res = await axios.get(`https://backoffice.gourdefriendly.green/establishments/${id}`);
//         return res.data.establishment;
//     } catch (err) {
//         console.error(err);
//         return null;
//     }
// }

async function fetchEstablishment(id) {
    try {
        const res = await axiosInstance.get(`/establishments/${id}`);
        return res.data.establishment;
    } catch (err) {
        console.error(err);
        return null;
    }
}

function ManageSalesPage() {
    const { id } = useParams();
    const [establishment, setEstablishment] = useState(null);
    const navigate = useNavigate(); // Ajoutez ceci

    const classes = useStyles();

    useEffect(() => {
        fetchEstablishment(id).then(setEstablishment);
    }, [id]);

    if (!establishment) return <p>Loading...</p>;

    return (
        <div>
            <AdminNavbar establishmentId={id} name={establishment.name} />
            <IconButton onClick={() => navigate(`/accueil`)} color="primary" aria-label="retour">
  <ArrowBackIcon />
</IconButton>
            <Typography variant="h4" component="h1" className={classes.root}>
                Gestion des ventes pour l'établissement {establishment.name}
            </Typography>
            {/* Ajoutez ici vos composants et votre logique pour la gestion des ventes */}
        </div>
    );
}

export default ManageSalesPage;
