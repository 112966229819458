import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CHRInventory from '../../components/gestion_stocks_mug/CHRInventory';
import SearchEstablishment from '../../components/gestion_stocks_mug/SearchEstablishment';
import ManageStock from '../../components/gestion_stocks_mug/ManageStock';
import Navbar from '../../components/Navbar';
import EtablissementAdd from '../../components/gestion_stocks_mug/EtablissementAdd';
import ThresholdSetter from '../../components/gestion_stocks_mug/ThresholdSetter';
import GourdeOrderForm from '../../components/gestion_stocks_mug/GourdeOrderForm';
import GourdeOrderHistory from '../../components/gestion_stocks_mug/GourdeOrderHistory';
import GourdeOrderStatusUpdater from '../../components/gestion_stocks_mug/GourdeOrderStatusUpdater';
import '../../components/gestion_stocks_mug/StockManagement.css';
import PendingGourdeOrders from '../../components/gestion_stocks_mug/PendingGourdeOrders';
import GourdeReturnForm from '../../components/gestion_stocks_mug/GourdeReturnForm';
import GourdeReturnListEtablissement from '../../components/gestion_stocks_mug/GourdeReturnListEtablissement';

import CHRReport from '../../components/gestion_stocks_mug/CHRReport';
import PeriodReport from '../../components/gestion_stocks_mug/PeriodReport';
import TrendAnalysis from '../../components/gestion_stocks_mug/TrendAnalysis';


// Import des nouveaux composants pour la gestion des fournisseurs
import SupplierList from '../../components/gestion_stocks_mug/SupplierList';
import AddSupplier from '../../components/gestion_stocks_mug/AddSupplier';
import UpdateSupplier from '../../components/gestion_stocks_mug/UpdateSupplier';
import SupplierOrderHistory from '../../components/gestion_stocks_mug/SupplierOrderHistory';

import axiosInstance from '../../services/axiosInstance';


function StockMugManagementView() {
    const [selectedEtabId, setSelectedEtabId] = useState(0);
    const [showEtablissementAddButton, setShowEtablissementAddButton] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [Refresh, setRefresh] = useState(false)

    // useEffect(() => {
    //     if (selectedEtabId !== 0) {
    //         axios.get(`https://backoffice.gourdefriendly.green/stock_mug/inventory/exists/${selectedEtabId}`)
    //             .then(response => {
    //                 setShowEtablissementAddButton(!response.data);
    //             })
    //             .catch(error => {
    //                 console.error("Erreur lors de la vérification de l'établissement :", error);
    //             });
    //     }
    // }, [selectedEtabId]);

    useEffect(() => {
        if (selectedEtabId !== 0) {
            axiosInstance.get(`/stock_mug/inventory/exists/${selectedEtabId}`)
                .then(response => {
                    setShowEtablissementAddButton(!response.data);
                })
                .catch(error => {
                    console.error("Erreur lors de la vérification de l'établissement :", error);
                });
        }
    }, [selectedEtabId]);

    return (
        <>
            <Navbar />
            <div className="stock-management">
                <h1>Gestion des stocks des mugs</h1>
                <div className="section">
                    <SearchEstablishment onSelectEtab={setSelectedEtabId} />
                </div>
                {showEtablissementAddButton && <EtablissementAdd etablissementId={selectedEtabId} />}
                <div className="section">
                    <CHRInventory onSelectEtab={setSelectedEtabId} selectedEtabId={selectedEtabId} Refresh={Refresh} setRefresh={setRefresh} />
                </div>
                {
                    selectedEtabId !== 0 &&
                    <>
                        <h2 className="section-title">Ajout/Retrait de gourdes</h2>
                        <div className="section-content">
                            <ManageStock etabId={selectedEtabId} setRefresh={setRefresh} />
                        </div>

                        <h2 className="section-title">Modifier le seuil d'alerte</h2>
                        <div className="section-content">
                            <ThresholdSetter etabId={selectedEtabId} onThresholdUpdate={() => setRefresh(true)} />
                        </div>

                        <h2 className="section-title">Commandes de gourdes</h2>
                        <div className="section-content">
                            <div className="section">
                                <h3>Passer une commande</h3>
                                <GourdeOrderForm etabId={selectedEtabId} setRefresh={setRefresh} />
                            </div>
                            <div className="section">
                                <h3>Historique des commandes</h3>
                                <GourdeOrderHistory etabId={selectedEtabId} onSelectOrder={setSelectedOrderId} Refresh={Refresh} setRefresh={setRefresh} /> {/* Transmettez la fonction pour gérer l'ID de commande */}
                            </div>
                            {
                                selectedOrderId != null &&
                                <div className="section">
                                    <h3>Mise à jour du statut de la commande</h3>
                                    <GourdeOrderStatusUpdater orderId={selectedOrderId} setRefresh={setRefresh} /> {/* Transmettez l'ID de commande au composant */}
                                </div>

                            }



                        </div>
                        <h2 className="section-title">Rapport </h2>
                        <div className="section-content">
                        <div className="section">
                            {/* <h3>Rapport des gourdes distribuées</h3> */}
                            <CHRReport etabId={selectedEtabId} />
                        </div>
                        
                        <div className="section">
                    {/* <h3>Analyse des tendances de consommation</h3> */}
                    <TrendAnalysis etabId={selectedEtabId} />
                </div>

                            
                        </div>
                        <h2 className="section-title">Retour de gourde</h2>

                        <div className="section-content">
                        <div className="section">
                                
                                <GourdeReturnForm etabId={selectedEtabId}  Refresh={Refresh} setRefresh={setRefresh} /> {/* Transmettez la fonction pour gérer l'ID de commande */}
                                <GourdeReturnListEtablissement  etabId={selectedEtabId}  Refresh={Refresh} setRefresh={setRefresh} /> 
                            </div>

                        </div>
                  

                    </>
                }
                <div className="section">
                <h2 className="section-title">Toutes les commandes en attente</h2>
                    <PendingGourdeOrders Refresh={Refresh} setRefresh={setRefresh} onSelectEtab={setSelectedEtabId} />
                </div>
             


                <h2 className="section-title">Gestion des fournisseurs</h2>
                <div className="section-content">
                    <div className="section">
                        <h3>Liste des fournisseurs</h3>
                        <SupplierList />
                    </div>
                    <div className="section">
                        <h3>Ajouter un fournisseur</h3>
                        <AddSupplier />
                    </div>
                    {/* <div className="section">
                        <h3>Historique des commandes du fournisseur</h3>
                        <SupplierOrderHistory />
                    </div> */}
                </div>

                <div className="section">
                    {/* <h3>Rapport des gourdes distribuées par période</h3> */}
                    <PeriodReport startDate="2023-01-01" endDate="2023-12-31" /> {/* Vous pouvez remplacer ces dates par celles souhaitées */}
                </div>
                
            </div>
        </>
    );
}

export default StockMugManagementView;
