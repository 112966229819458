import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';
function GourdeOrderHistory({ etabId, onSelectOrder, Refresh, setRefresh }) {
    const [orders, setOrders] = useState([]);
    const [totalGourdes, setTotalGourdes] = useState(0);  // Nouvel état pour le total de gourdes

    // useEffect(() => {
    //     fetch(`https://backoffice.gourdefriendly.green/stock_mug/gourde-orders/history/${etabId}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             setOrders(data.orders);   // Mettre à jour pour utiliser data.orders
    //             setTotalGourdes(data.total_gourdes_pending);   // Mettre à jour avec le total des gourdes
    //             setRefresh(false);
    //         })
    //         .catch(error => {
    //             console.error("Erreur lors de la récupération de l'historique des commandes :", error);
    //         });
    // }, [etabId, Refresh]);

    
useEffect(() => {
    axiosInstance.get(`/stock_mug/gourde-orders/history/${etabId}`)
        .then(response => {
            setOrders(response.data.orders);   // Mettre à jour pour utiliser data.orders
            setTotalGourdes(response.data.total_gourdes_pending);   // Mettre à jour avec le total des gourdes
            setRefresh(false);
        })
        .catch(error => {
            console.error("Erreur lors de la récupération de l'historique des commandes :", error);
        });
}, [etabId, Refresh]);

    return (
        <div className="gourde-order-history">
            <p>Total de mugs commandées : {totalGourdes}</p>  {/* Affichage du total de gourdes */}
            <table>
                <thead>
                    <tr>
                        <th>ID Commande</th>
                        <th>Quantité commandée</th>
                        <th>Date de commande</th>
                        <th>Date de réception</th>
                        <th>Statut</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.order_id} onClick={() => onSelectOrder(order.order_id)}>
                            <td>{order.order_id}</td>
                            <td>{order.quantity_ordered}</td>
                            <td>{order.date_ordered}</td>
                            <td>{order.date_received || 'N/A'}</td>
                            <td>{order.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default GourdeOrderHistory;
