// import { useState, useEffect } from 'react';

// function useAdmin() {
//     const [isAdmin, setIsAdmin] = useState(localStorage.getItem('admin') === 'true');


//   // useEffect(() => {


//   //   // Récupérez le statut d'admin depuis le localStorage
//   //   const adminStatus = localStorage.getItem('admin');
    
//   //   // Mettez à jour le statut d'admin dans l'état local
//   //   setIsAdmin(adminStatus === 'true');

//   // }, []); // Le tableau vide signifie que cet effet ne s'exécutera qu'une seule fois, similaire à componentDidMount



// return localStorage.getItem('admin') == "true"

// }

// export default useAdmin;

import { useState, useEffect } from 'react';

function useAdmin() {
  const [isAdmin, setIsAdmin] = useState(false);

  console.log(isAdmin)

  useEffect(() => {
    const adminStatus = localStorage.getItem('admin');
    setIsAdmin(adminStatus === 'true');
  }, []); // Le tableau vide signifie que cet effet ne s'exécutera qu'une seule fois

  return isAdmin;
}

export default useAdmin;