import React, { useState, useEffect } from 'react';
import './StockManagement.css'
import axiosInstance from '../../services/axiosInstance';
function SearchEstablishment({ onSelectEtab }) {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    // useEffect(() => {
    //     if (!query) return;

    //     // Faites une requête API pour rechercher des établissements
    //     // Par exemple, '/api/search-establishment?query=' + query
        
    //     fetch('https://backoffice.gourdefriendly.green/stock_mug/etablissements?city=' + query)
    //         .then(response => response.json())
    //         .then(data => setResults(data));
    // }, [query]);

    useEffect(() => {
        if (!query) return;
    
        axiosInstance.get(`/stock_mug/etablissements?city=${query}`)
            .then(response => {
                setResults(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la recherche des établissements:', error);
            });
    }, [query]);

    return (
        <div>
            <h2>Rechercher un établissement</h2>
            <input 
                type="text" 
                value={query} 
                onChange={e => setQuery(e.target.value)} 
                placeholder="Recherche par ville" 
            />
            <ul>
                {results.map(result => (
                    <li key={result.id} onClick={() => selectEstablishment(result.id)}>
                        {result.name} - {result.city}
                    </li>
                ))}
            </ul>
        </div>
    );

    function selectEstablishment(id) {

        onSelectEtab(id)
    }
}


export default SearchEstablishment;