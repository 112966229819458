import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes.js';
import ProtectedRoute from './components/ProtectedRoute'; // Assurez-vous d'importer votre composant ProtectedRoute
import useAdmin from './utile/useAdmin'; // Importez votre hook useAdmin
import NotFound from './components/NotFound'; // Assurez-vous d'importer le composant NotFound



function App() {
  return (
    <Router>
      <Routes>
        {routes.map((route, index) => (
          route.protected ? (
            <Route key={index} path={route.path} element={
              <ProtectedRoute requireAdmin={route.requireAdmin}>
                {route.element}
              </ProtectedRoute>
            } />
          ) : (
            <Route key={index} path={route.path} element={route.element} />
          )
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
