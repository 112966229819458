// import React, { useState, useEffect } from 'react';
// import { TextField, Button, makeStyles, Typography } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
//     root: {
//       marginTop: theme.spacing(4),
//       marginBottom: theme.spacing(4),
//       padding: "2rem"
//     },
//     input: {
//       margin: theme.spacing(2, 0),
//     },
//     title: {
//       marginBottom: theme.spacing(2),
//     },
//     card: {
//       minWidth: 275,
//       maxWidth: 400,
//     },
//   }));

// // Horaires de l'établissement
// function EstablishmentHours({ hours, onHoursChange }) {
//     const classes = useStyles();
  
//     const handleHoursChange = (day, newHours) => {
//       const updatedHours = { ...hours, [day]: newHours };
//       onHoursChange(updatedHours);
//     };
  
//     return (
//       <div className={classes.root}>
//         <Typography variant="h4" component="h1" className={classes.title}>
//           Horaires de l'établissement
//         </Typography>
//         {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map(day => (
//           <TextField
//             key={day}
//             value={hours[day] || ''}
//             onChange={e => handleHoursChange(day, e.target.value)}
//             label={day}
//             variant="outlined"
//             fullWidth
//             className={classes.input}
//           />
//         ))}
//       </div>
//     );
//   }
  
//   export default EstablishmentHours ;

import React, { useState, useEffect } from 'react';
import { TextField, Button, makeStyles, Typography, Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: "2rem"
    },
    input: {
      margin: theme.spacing(1),
      width: '200px'
    },
    dayContainer: {
      margin: theme.spacing(2, 0),
    },
    timeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    addButton: {
      marginLeft: theme.spacing(2),
    }
}));

function EstablishmentHours({ initialHours, onHoursChange }) {
  const classes = useStyles();
  const [hours, setHours] = useState(initialHours);

  // Initialiser les horaires pour chaque jour de la semaine
  useEffect(() => {
    const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const updatedHours = { ...hours };
    days.forEach(day => {
      if (!updatedHours[day]) {
        updatedHours[day] = { statut: "fermé", horaires: [] };
      }
    });
    setHours(updatedHours);
  }, [initialHours]);

  const handleStatusChange = (day, isOpen) => {
    const newStatus = isOpen ? "ouvert" : "fermé";
    const newHours = isOpen ? { statut: newStatus, horaires: [{ouverture: "", fermeture: ""}] } : { statut: newStatus, horaires: [] };
    setHours({ ...hours, [day]: newHours });
    onHoursChange({ ...hours, [day]: newHours });
  };

  const handleHoursChange = (day, index, timeType, value) => {
    const newHours = { ...hours[day] };
    newHours.horaires[index][timeType] = value;
    setHours({ ...hours, [day]: newHours });
    onHoursChange({ ...hours, [day]: newHours });
  };

  const addTimeSlot = (day) => {
    // S'assurer que les horaires pour le jour spécifié sont initialisés
    if (!hours[day] || !Array.isArray(hours[day].horaires)) {
      // Initialiser les horaires pour ce jour si ce n'est pas déjà fait
      setHours({
        ...hours,
        [day]: { statut: "ouvert", horaires: [{ ouverture: "", fermeture: "" }] }
      });
    } else {
      // Ajouter un nouveau créneau horaire au tableau existant
      const newHours = { ...hours[day] };
      newHours.horaires.push({ ouverture: "", fermeture: "" });
      setHours({ ...hours, [day]: newHours });
      onHoursChange({ ...hours, [day]: newHours });
    }
  };

  const removeTimeSlot = (day, index) => {
    const newHours = { ...hours[day] };
    newHours.horaires.splice(index, 1);
    setHours({ ...hours, [day]: newHours });
    onHoursChange({ ...hours, [day]: newHours });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Horaires de l'établissement
      </Typography>
      {Object.keys(hours).map((day) => (
        <div key={day} className={classes.dayContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hours[day].statut === "ouvert"}
                onChange={(e) => handleStatusChange(day, e.target.checked)}
              />
            }
            label={day}
          />
          {hours[day].statut === "ouvert" && Array.isArray(hours[day].horaires) &&
            hours[day].horaires.map((plage, index) => (
              <div key={index} className={classes.timeContainer}>
                <TextField
                  type="time" 
                  value={plage.ouverture}
                  onChange={(e) => handleHoursChange(day, index, 'ouverture', e.target.value)}
                  label="Heure d'ouverture"
                  variant="outlined"
                  className={classes.input}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type="time" 
                  value={plage.fermeture}
                  onChange={(e) => handleHoursChange(day, index, 'fermeture', e.target.value)}
                  label="Heure de fermeture"
                  variant="outlined"
                  className={classes.input}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => removeTimeSlot(day, index)}
                  InputLabelProps={{ shrink: true }}
                >
                  Supprimer
                </Button>
              </div>
            ))
          }
          {hours[day].statut === "ouvert" && (
            <Button
              variant="contained"
              color="primary"
              className={classes.addButton}
              onClick={() => addTimeSlot(day)}
            >
              Ajouter un créneau horaire
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}

export default EstablishmentHours;

