import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';

function GourdeReturnListEtablissement({etabId , Refresh, setRefresh}) {
    const [returns, setReturns] = useState([]);

    useEffect(() => {
        console.log(`https://backoffice.gourdefriendly.green/stock/gourde-returns/${etabId}`)
        axiosInstance.get(`https://backoffice.gourdefriendly.green/stock/gourde-returns/etablissement/${etabId}`)
            .then(response => {
                console.log(response)
                setReturns(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des retours de gourdes:', error);
            });
    }, [Refresh]);

    return (
        <div>
            <h2>Liste des retours de gourdes</h2>
            <table>
                <thead>
                    <tr>
                        {/* <th>ID Retour</th>
                        <th>ID Établissement</th> */}
                        <th>Quantité</th>
                        <th>Raison</th>
                        <th>date de retour</th>
                    </tr>
                </thead>
                <tbody>
                    {returns.map(ret => (
                        <tr key={ret.id}>
                            {/* <td>{ret.id}</td>
                            <td>{ret.etablissement_id}</td> */}
                            <td>{ret.quantity}</td>
                            <td>{ret.reason}</td>
                            <td>{ret.date_returned}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default GourdeReturnListEtablissement;
