import React, { useState  , useEffect } from 'react';
import { makeStyles, Button, TextField, Typography, Paper, Container, Grid, Card, CardContent, CardMedia , IconButton , CardActions } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(3),
      background: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[3],
    },
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
    inputField: {
      marginBottom: theme.spacing(2),
    },
    photoButton: {
      margin: theme.spacing(1, 0),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    ingredientList: {
      marginTop: theme.spacing(2),
      listStyle: 'none',
      padding: 0,
    },
    ingredientItem: {
      padding: theme.spacing(1, 0),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    imagePreview: {
        marginTop: theme.spacing(2),
        maxWidth: '200px', // Définissez ici la largeur maximale désirée
        height: 'auto',
      },

      card: {
        display: 'flex',
        margin: theme.spacing(2),
        justifyContent: 'space-between',
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        flex: '1 0 auto',
      },
      cover: {
        width: 151,
      },

      card: {
        margin: theme.spacing(2),
      },
      media: {
        height: 140, // ou tout autre hauteur fixe que vous préférez
        width: '100%', // cela va s'assurer que la largeur de l'image remplit le conteneur
        objectFit: 'contain', // cela garde les proportions de l'image tout en s'assurant qu'elle s'adapte à l'espace disponible
        backgroundSize: 'contain', // assurez-vous que l'ensemble de l'image est visible
      },
      input: {
        display: 'none',
      },
  }));

function ManageIngredients({ initialIngredients, setIngredientsinitial ,onIngredientsChange , imagePreviewUrl, setImagePreviewUrl,ingredientForm,setIngredientForm}) {
const classes = useStyles();
const [ingredients, setIngredients] = useState([...initialIngredients]);



  console.log("initial : ",initialIngredients)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIngredientForm({ ...ingredientForm, [name]: value });
  };

  // const handlePhotoChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.type.match('image.*')) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       // Mettez à jour l'état de l'aperçu de l'image ici, après que la lecture soit terminée
  //       setImagePreviewUrl(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     // S'il n'y a pas de fichier, réinitialisez l'aperçu de l'image
  //     setImagePreviewUrl(null);
  //   }
  // };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match('image.*')) {
      setIngredientForm({ ...ingredientForm, photo: file });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
       
      };
      reader.readAsDataURL(file);
    } else {
      setIngredientForm({ ...ingredientForm, photo: null });
      setImagePreviewUrl(null);
    }
  };
  
  // const handleSubmit = () => {
  //   // Ajoutez l'ingrédient actuel avec son aperçu d'image à la liste des ingrédients
  //   const newIngredient = {
  //     ...ingredientForm,
  //     photo: imagePreviewUrl // Utilisez l'état de l'aperçu de l'image
  //   };
  
  //   setIngredients([...ingredients, newIngredient]);
    
  //   // Réinitialisez le formulaire d'ingrédients et l'état de l'aperçu de l'image pour la prochaine saisie
  //   setIngredientForm({ name: '', brand: '', description: '', photo: null });
  //   setImagePreviewUrl(null);
  // };

  const handleSubmit = () => {
    const newIngredients = [...ingredients, ingredientForm];
    setIngredients(newIngredients);
    onIngredientsChange(newIngredients);
    // Réinitialisez le formulaire d'ingrédients
    setIngredientForm({ name: '', brand: '', description: '', photo: null });
    setImagePreviewUrl(null);
  };
  
  const handleDeleteIngredient = (indexToDelete) => {
    const newIngredients = ingredients.filter((_, index) => index !== indexToDelete);
    setIngredients(newIngredients);
    onIngredientsChange(newIngredients);
  };
  


  // useEffect(() => {
  //   if(onIngredientsChange) {
  //     onIngredientsChange(ingredients);
  //   }
  // }, [onIngredientsChange]);

  useEffect(() => {

    setIngredients(initialIngredients || []);

  }, [initialIngredients]);




  return (
    <>
      <Container maxWidth="sm">
        <Paper className={classes.container}>
          <Typography variant="h5">Ajouter un ingrédient</Typography>
          <div className={classes.formControl}>
            <TextField label="Nom" name="name" value={ingredientForm.name} onChange={handleInputChange} className={classes.inputField} />
            <TextField label="Marque" name="brand" value={ingredientForm.brand} onChange={handleInputChange} className={classes.inputField} />
            <TextField label="Description" name="description" value={ingredientForm.description} onChange={handleInputChange} multiline className={classes.inputField} />
            <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file" onChange={handlePhotoChange} />
            <label htmlFor="icon-button-file">
              <IconButton color="primary" aria-label="upload picture" component="span" className={classes.photoButton}>
                <PhotoCamera />
              </IconButton>
            </label>
            {imagePreviewUrl && <img src={imagePreviewUrl} alt="Aperçu" className={classes.imagePreview} />}

            <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
              Valider l'ingrédient
            </Button>
          </div>
          <Typography variant="h5">Ingrédients ajoutés</Typography>
          <Grid container spacing={2}>
  {ingredients.map((ingredient, index) => (
    <Grid item xs={12} sm={6} key={index}>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h6">
            {ingredient.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Marque: {ingredient.brand}
          </Typography>
          <Typography variant="body1" component="p">
            {ingredient.description}
          </Typography>
        </CardContent>
        {/* <Typography variant="body1" component="p">
        
            {ingredient.photo}
          </Typography> */}
          {ingredient.photo && (
        <CardMedia
          className={classes.media}
          // Si 'ingredient.photo' est une chaîne de caractères, considérez-la comme une URL, sinon créez un URL à partir de l'objet File
          image={typeof ingredient.photo === 'string' ? ingredient.photo : URL.createObjectURL(ingredient.photo)}
          title={`Ingrédient ${ingredient.name}`}
        />
      )}

              <CardActions>
                <Button color="secondary" onClick={() => handleDeleteIngredient(index)}>
                  Supprimer
                </Button>
              </CardActions>
      </Card>
    </Grid>
  ))}
</Grid>
        </Paper>
      </Container>
    </>
  );
}

export default ManageIngredients;
