import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';
import './StockManagement.css'
function CHRInventory({ onSelectEtab, selectedEtabId, Refresh, setRefresh }) {

    const [etabs, setEtabs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // useEffect(() => {

    //     console.log(selectedEtabId)
    //     let url = `https://backoffice.gourdefriendly.green/stock_mug/etablissements/paginated?page=${currentPage}`;

    //     if (selectedEtabId) {
    //         url += `&etabId=${selectedEtabId}`;
    //     }

    //     fetch(url)
    //         .then(response => response.json())
    //         .then(data => {
    //             setEtabs(data.etablissements);
    //             setTotalPages(data.total_pages);
    //             setRefresh(false)
    //         });
    // }, [currentPage, selectedEtabId, Refresh]);

    useEffect(() => {
        console.log(selectedEtabId);
        let url = `/stock_mug/etablissements/paginated?page=${currentPage}`;
    
        if (selectedEtabId) {
            url += `&etabId=${selectedEtabId}`;
        }
    
        axiosInstance.get(url)
            .then(response => {
                setEtabs(response.data.etablissements);
                setTotalPages(response.data.total_pages);
                setRefresh(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des établissements paginés:', error);
            });
    }, [currentPage, selectedEtabId, Refresh]);


    const handleRowClick = (etabId) => {
        onSelectEtab(etabId);
    };



    const afficherTousEtablissement = () => {

        onSelectEtab(0);

    }



    // if (!etabData) return <div>Chargement des données...</div>;



    return (
        <div>
            <h2>Inventaire des CHR</h2>

            {selectedEtabId != 0 && <button onClick={afficherTousEtablissement} style={{ marginBottom: 20 }}>Afficher tous les établissements</button>


            }

            <table>
                <thead>
                    <tr>
                        <th>ID CHR</th>
                        <th>CHR</th>
                        <th>Adresse</th>
                        <th>Ville</th>
                        <th>Insee</th>
                        <th>Email du reponsable</th>
                        <th>Téléphone du reponsable</th>
                        <th>Stock de mugs</th>
                        <th>Seuil</th>
                        <th>Date de la dernière livraison</th>
                        <th>Prévision rupture</th>
                        <th>Suggestion commande pour avoir du stock pour 15 jours</th>
                    </tr>
                </thead>
                <tbody>
                    {etabs.map(etabData => (
                        <tr
                            key={etabData.id}
                            onClick={() => handleRowClick(etabData.id)}
                            style={etabData.low_stock_alert ? { backgroundColor: 'red', color: 'white' } : {}}
                        >
                            <td>{etabData.id}</td>
                            <td>{etabData.etablissement_name}</td>
                            <td>{etabData.address}</td>
                            <td>{etabData.city}</td>
                            <td>{etabData.insee}</td>
                            <td>{etabData.mail}</td>
                            <td>{etabData.phone}</td>
                            <td>{etabData.current_stock}</td>
                            <td>{etabData.threshold}</td>
                            <td>{etabData.date_last_update}</td>
                            <td>{parseInt(etabData.days_before_out_of_stock)} jours</td>
                            <td>{parseInt(etabData.suggested_order_quantity)} mugs</td>
                        </tr>
                    ))}



                </tbody>
            </table>
        </div>
    );
}


export default CHRInventory;