import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';
function SupplierOrderHistory({ supplierId }) {
    const [orders, setOrders] = useState([]);

    // useEffect(() => {
    //     axios.get(`https://backoffice.gourdefriendly.green/stock_mug/suppliers/${supplierId}/orders-history`)
    //         .then(response => {
    //             setOrders(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Erreur lors de la récupération de l\'historique:', error);
    //         });
    // }, [supplierId]);
    useEffect(() => {
        axiosInstance.get(`/stock_mug/suppliers/${supplierId}/orders-history`)
            .then(response => {
                setOrders(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération de l\'historique:', error);
            });
    }, [supplierId]);

    return (
        <div>
            {/* <h4>Historique des commandes pour le fournisseur {supplierId}</h4> */}
            <ul>
                {orders.map(order => (
                    <li key={order.id}>
                        Commande ID: {order.id}, Quantité: {order.quantity}, Date: {order.date}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SupplierOrderHistory;
