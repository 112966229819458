import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, TextField, Typography } from '@material-ui/core';
import AdminNavbar from '../../components/AdminNavbar';
import { useNavigate } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: "2rem"
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    card: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: 15,
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    },
  }));
// Ce composant représente un événement individuel
function EventCard({ event, onEventChange, onDelete }) {

    const classes = useStyles();

    const handleChange = (field, value) => {
        onEventChange({ ...event, [field]: value });
    };

    return (
        <Card className={classes.card}>

            <CardContent>
                <TextField
                    value={event.name}
                    onChange={e => handleChange('name', e.target.value)}
                    label="Nom"
                    variant="outlined"
                    fullWidth
                />
                {/* Ajouter d'autres champs comme la date, le lieu, etc. */}
            </CardContent>
            <Button onClick={onDelete}>Supprimer</Button>
        </Card>
    );
}

// async function fetchEstablishment(id) {
//     try {
//         const res = await axios.get(`https://backoffice.gourdefriendly.green/establishments/${id}`);
//         return res.data.establishment;
//     } catch (err) {
//         console.error(err);
//         return null;
//     }
// }

async function fetchEstablishment(id) {
    try {
        const res = await axiosInstance.get(`/establishments/${id}`);
        return res.data.establishment;
    } catch (err) {
        
        console.error(err);
        return null;
    }
}

// async function updateEstablishment(partnerUserId, establishment) {
//     try {
//         const res = await axios.patch(`https://backoffice.gourdefriendly.green/partner_users/${partnerUserId}/establishments/${establishment.id}`, establishment);
//         return res.data.establishment;
//     } catch (err) {
//         console.error(err);
//         return null;
//     }
// }


async function updateEstablishment(partnerUserId, establishment) {
    try {
        const res = await axiosInstance.patch(`/partner_users/${partnerUserId}/establishments/${establishment.id}`, establishment);
        return res.data.establishment;
    } catch (err) {
        console.error(err);
        return null;
    }
}

// Cette page affiche tous les événements et permet d'en ajouter de nouveaux
function EventManagement() {
    const { id_etablissement } = useParams();
    const [establishment, setEstablishment] = useState(null);
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const classes = useStyles();


// const partnerUserId = process.env.REACT_APP_PARTNER_USER_ID; // Remplacez cela par votre ID d'utilisateur partenaire
const partnerUserId = localStorage.getItem('user_id')

useEffect(() => {
    fetchEstablishment(id_etablissement).then(setEstablishment);
}, [id_etablissement]);


    // useEffect(() => {
    //     // Remplacez cette URL par l'URL de votre API pour obtenir les événements
    //     axios.get('http://localhost:5000/events')
    //         .then(res => setEvents(res.data))
    //         .catch(err => console.error(err));
    // }, []);

    if (!establishment) return <p>Loading...</p>;


    const handleEventChange = (index, updatedEvent) => {
        const updatedEvents = [...events];
        updatedEvents[index] = updatedEvent;
        setEvents(updatedEvents);
        // Faire une requête à l'API pour mettre à jour l'événement...
    };

    const handleDelete = (index) => {
        // Faire une requête à l'API pour supprimer l'événement...
        const updatedEvents = [...events];
        updatedEvents.splice(index, 1);
        setEvents(updatedEvents);
    };

    const handleAdd = () => {
        // Faire une requête à l'API pour ajouter un nouvel événement...
        setEvents([...events, { name: '', /* autres champs */ }]);
    };

    return (
        <div>
            <AdminNavbar establishmentId={id_etablissement} name={establishment.name} />

            <Typography variant="h4" component="h1" className={classes.title}>
                Gestion des événements
            </Typography>
            {events.map((event, index) => (
                <EventCard
                    key={index}
                    event={event}
                    onEventChange={updatedEvent => handleEventChange(index, updatedEvent)}
                    onDelete={() => handleDelete(index)}
                />
            ))}
            <Button onClick={handleAdd}>Ajouter un événement</Button>
        </div>
    );
}

export default EventManagement;
