import React, { useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';
function GourdeReturnForm({etabId , Refresh, setRefresh}) {
    // const [etablissementId, setEtablissementId] = useState('');
    const [quantity, setQuantity] = useState('');
    const [reason, setReason] = useState('');

    const handleSubmit = () => {
        const data = {
            etablissement_id: etabId,
            quantity: quantity,
            reason: reason
        };

        // axios.post('https://backoffice.gourdefriendly.green/stock_mug/gourde-returns', data)
        //     .then(response => {
        //         console.log(response.data)
        //         setRefresh(true)
        //     })
        //     .catch(error => {
        //         console.error('Erreur lors de l\'enregistrement du retour de gourde:', error);
        //     });

        axiosInstance.post('/stock_mug/gourde-returns', data)
    .then(response => {
        console.log(response.data);
        setRefresh(true);
    })
    .catch(error => {
        console.error('Erreur lors de l\'enregistrement du retour de gourde:', error);
    });

    };

    return (
        <div>
            <h2>Enregistrer un retour de gourde</h2>
            {/* <div>
                <label>Établissement ID:</label>
                <input type="text" value={etablissementId} onChange={e => setEtablissementId(e.target.value)} />
            </div> */}
            <div>
                <label>Quantité :</label>
                <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} />
            </div>
            <div>
                <label>Raison :</label>
                <textarea value={reason} onChange={e => setReason(e.target.value)}></textarea>
            </div>
            <button onClick={handleSubmit}>Enregistrer le retour</button>
        </div>
    );
}

export default GourdeReturnForm;
