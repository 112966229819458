import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';
function UpdateSupplier({ supplierId, supplierDetails, onSupplierUpdated, onSupplierDeleted , setRefresh }) {
    const [name, setName] = useState(supplierDetails ? supplierDetails.name : '');
    const [contactDetails, setContactDetails] = useState(supplierDetails ? supplierDetails.contact_details : '');

    useEffect(() => {
        if (!supplierDetails && supplierId) {
            fetchSupplierDetails();
        }
    }, [supplierId, supplierDetails]);

    // const fetchSupplierDetails = () => {
    //     axios.get(`https://backoffice.gourdefriendly.green/stock_mug/suppliers/${supplierId}`)
    //         .then(response => {
    //             const supplier = response.data;
    //             setName(supplier.name);
    //             setContactDetails(supplier.contact_details);
    //         })
    //         .catch(error => {
    //             console.error('Erreur lors de la récupération du fournisseur:', error);
    //         });
    // }

    
const fetchSupplierDetails = () => {
    axiosInstance.get(`/stock_mug/suppliers/${supplierId}`)
        .then(response => {
            const supplier = response.data;
            setName(supplier.name);
            setContactDetails(supplier.contact_details);
        })
        .catch(error => {
            console.error('Erreur lors de la récupération du fournisseur:', error);
        });
};

    // const handleUpdate = (e) => {
    //     e.preventDefault();
    //     axios.put(`https://backoffice.gourdefriendly.green/stock_mug/suppliers/${supplierId}`, {
    //         name: name,
    //         contact_details: contactDetails
    //     })
    //     .then(response => {
    //         console.log('Fournisseur mis à jour avec succès!', response.data);
    //         onSupplierUpdated && onSupplierUpdated();
    //         setRefresh(true)
    //     })
    //     .catch(error => {
    //         console.error('Erreur lors de la mise à jour du fournisseur:', error);
    //     });
    // }

    const handleUpdate = (e) => {
        e.preventDefault();
        axiosInstance.put(`/stock_mug/suppliers/${supplierId}`, {
            name: name,
            contact_details: contactDetails
        })
        .then(response => {
            console.log('Fournisseur mis à jour avec succès!', response.data);
            onSupplierUpdated && onSupplierUpdated();
            setRefresh(true)
        })
        .catch(error => {
            console.error('Erreur lors de la mise à jour du fournisseur:', error);
        });
    };

    // const handleDelete = () => {
    //     if (window.confirm('Êtes-vous sûr de vouloir supprimer ce fournisseur?')) {
    //         axios.delete(`https://backoffice.gourdefriendly.green/stock_mug/suppliers/${supplierId}`)
    //         .then(response => {
    //             console.log('Fournisseur supprimé avec succès!', response.data);
    //             onSupplierDeleted && onSupplierDeleted();
    //         })
    //         .catch(error => {
    //             console.error('Erreur lors de la suppression du fournisseur:', error);
    //         });
    //     }
    // }

    const handleDelete = () => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce fournisseur?')) {
            axiosInstance.delete(`/stock_mug/suppliers/${supplierId}`)
            .then(response => {
                console.log('Fournisseur supprimé avec succès!', response.data);
                onSupplierDeleted && onSupplierDeleted();
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du fournisseur:', error);
            });
        }
    };

    return (
        <div>
            <form onSubmit={handleUpdate}>
                <div>
                    <label>Nom du fournisseur:</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div>
                    <label>Détails du contact:</label>
                    <textarea value={contactDetails} onChange={(e) => setContactDetails(e.target.value)} required></textarea>
                </div>
                <div>
                    <button type="submit">Mettre à jour le fournisseur</button>
                    <button type="button" onClick={handleDelete} style={{backgroundColor: 'red', color: 'white'}}>Supprimer le fournisseur</button>
                </div>
            </form>
        </div>
    );
}

export default UpdateSupplier;
