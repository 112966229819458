import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { Link as RouterLink , NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios'; 
function AdminNavbar({ establishmentId , name = "" }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Effacer le token du localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    
    // Rediriger vers la page de connexion
    navigate('/');

  };

//   const handleStripeDashboard = async () => {
//     try {
//         const response = await axios.get(`https://backoffice.gourdefriendly.green/establishments/${establishmentId}/stripe_dashboard`);
//         if (response.data.url) {
//             window.location.href = response.data.url;
//         }
//     } catch (error) {
//         console.error('Error fetching Stripe dashboard link:', error);
//     }
// };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
        Gestionnaire CHR | {name}
        </Typography>
        <Button color="inherit" component={RouterLink} to="/accueil">
          Accueil
        </Button>
        <Button color="inherit" component={NavLink} to={`/admin/${establishmentId}`} activeClassName="active-link">
          Gestion de l'établissement
        </Button>
        <Button color="inherit" component={NavLink} to={`/admin/manage-products/${establishmentId}`} activeClassName="active-link">
          Gestion des produits
        </Button>
        <Button color="inherit" component={NavLink} to={`/admin/manage-container/${establishmentId}`} activeClassName="active-link">
          Gestion des contenant
        </Button>
        <Button color="inherit" component={NavLink} to={`/admin/manage-shop/${establishmentId}`} activeClassName="active-link">
          Gestion des contenant à vendre
        </Button>
        {/* <Button color="inherit" component={NavLink} to={`/admin/create-categories/${establishmentId}`} activeClassName="active-link">
          Création des catégories
        </Button> */}
        {/* <Button color="inherit" onClick={handleStripeDashboard}>
                Tableau de bord Stripe
        </Button> */}
        {/* <Button color="inherit" component={NavLink} to={`/admin/manage-sales/${establishmentId}`} activeClassName="active-link">
          Gestion des ventes
        </Button>
        <Button color="inherit" component={NavLink} to={`/admin/manage-event/${establishmentId}`} activeClassName="active-link">
          Gestion des événements
        </Button> */}
         <Button color="inherit" onClick={handleLogout}>
          Déconnexion
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default AdminNavbar;
