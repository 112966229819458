import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';
function TrendAnalysis({ etabId }) {
    const [trends, setTrends] = useState([]);

    // useEffect(() => {
    //     fetch(`https://backoffice.gourdefriendly.green/stock_mug/report/trend-analysis/${etabId}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             setTrends(data);
    //         });
    // }, [etabId]);


    useEffect(() => {
        axiosInstance.get(`/stock_mug/report/trend-analysis/${etabId}`)
            .then(response => {
                setTrends(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des tendances:', error);
            });
    }, [etabId]);


    return (
        <div>
            <h2>Analyse des tendances de consommation</h2>
            <ul>
                {trends.map((trend, idx) => (
                    <li key={idx}>Mois: {trend.month}, Total distribué: {trend.total_distributed}</li>
                ))}
            </ul>
        </div>
    );
}

export default TrendAnalysis;
