import React, { useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';
function GourdeOrderStatusUpdater({ orderId , setRefresh }) {
    
    const [newStatus, setNewStatus] = useState('');

    // const handleStatusUpdate = () => {
    //     if (!orderId || !newStatus) {
    //         alert('Veuillez entrer un ID de commande et un statut valide.');
    //         return;
    //     }

    //     axios.put(`https://backoffice.gourdefriendly.green/stock_mug/gourde-orders/status/${orderId}`, { status: newStatus })
    //         .then(response => {
    //             setRefresh(true)

    //         })
    //         .catch(error => {
    //             console.error("Erreur lors de la mise à jour du statut de la commande :", error);
    //         });
    // };

    const handleStatusUpdate = () => {
        if (!orderId || !newStatus) {
            alert('Veuillez entrer un ID de commande et un statut valide.');
            return;
        }
    
        axiosInstance.put(`/stock_mug/gourde-orders/status/${orderId}`, { status: newStatus })
            .then(response => {
                setRefresh(true);
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour du statut de la commande :", error);
            });
    };

    return (
        <div className="gourde-order-status-updater">
            {/* <h2>Mise à jour du statut de la commande</h2> */}
            <div>
                <label>ID Commande :</label>
                <p>{orderId}</p>
            </div>
            <div>
                <label>Nouveau statut :</label>
                <select value={newStatus} onChange={e => setNewStatus(e.target.value)}>
                    <option value="">Sélectionnez un statut</option>
                    <option value="en attente">En attente</option>
                    <option value="livrée">Livrée</option>
                    <option value="annulée">Annulée</option>
                </select>
            </div>
            <button onClick={handleStatusUpdate}>Mettre à jour</button>
        </div>
    );
}

export default GourdeOrderStatusUpdater;
