import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: "2rem"
  },
  input: {
    margin: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

// Réseaux sociaux de l'établissement
function EstablishmentSocialMedia({ socialMedia, onSocialMediaChange }) {
  const classes = useStyles();

  const handleChange = (event) => {
    onSocialMediaChange({
      ...socialMedia,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Réseaux sociaux de l'établissement
      </Typography>
      <TextField
        name="facebook"
        value={socialMedia.facebook || ''}
        onChange={handleChange}
        label="Facebook URL"
        variant="outlined"
        fullWidth
        className={classes.input}
      />
      <TextField
        name="twitter"
        value={socialMedia.twitter || ''}
        onChange={handleChange}
        label="Twitter URL"
        variant="outlined"
        fullWidth
        className={classes.input}
      />
      <TextField
        name="linkedin"
        value={socialMedia.linkedin || ''}
        onChange={handleChange}
        label="LinkedIn URL"
        variant="outlined"
        fullWidth
        className={classes.input}
      />
      {/* Ajoutez d'autres plateformes de médias sociaux si nécessaire */}
    </div>
  );
}

export default EstablishmentSocialMedia;
