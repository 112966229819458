// views/adminEstablissement/ManageContainerCardsPage.js

import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Grid, makeStyles, Typography, Modal, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import axiosInstance from '../../services/axiosInstance';
import { MenuItem, Select, FormControl, InputLabel, Checkbox, FormControlLabel } from '@material-ui/core';




const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row', // change from 'column' to 'row'
        justifyContent: 'space-around', // change from 'space-around' to 'flex-start'
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    addButton: {
        margin: theme.spacing(2),
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },

    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        borderRadius: '10px',
        outline: 'none',
        boxShadow: theme.shadows[5],
    },
}));

function DeleteConfirmationModal({ open, onClose, onConfirm, containerCardId }) {
    const classes = useStyles();

    const handleConfirmClick = () => {
        onConfirm(containerCardId);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.modal}>
                <Typography variant="h6" align="center" gutterBottom>
                    Êtes-vous sûr de vouloir supprimer ce contenant ?
                </Typography>
                <Button variant="contained" color="primary" onClick={handleConfirmClick} fullWidth style={{ marginTop: '1rem' }}>
                    Oui
                </Button>
                <Button variant="contained" color="secondary" onClick={onClose} fullWidth style={{ marginTop: '1rem' }}>
                    Non
                </Button>
            </div>
        </Modal>
    );
}

function ManageContainerCardsPage() {
    const classes = useStyles();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [containerCardToDelete, setContainerCardToDelete] = useState(null);

    // const [containerCards, setContainerCards] = useState([
    //     { id: 1, brand: 'SportBrand', volume: '500ml', material: 'Plastic' },
    //     { id: 2, brand: 'EcoBrand', volume: '1L', material: 'Metal' },
    // ]);
    const [containerCards, setContainerCards] = useState([]);



    const [addModalOpen, setAddModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedContainerCard, setSelectedContainerCard] = useState(null);
    const [type, setType] = useState('');
    const [isTransparent, setIsTransparent] = useState(false);
    // const fetchContainerCards = () => {
    //     axios.get('https://backoffice.gourdefriendly.green/containers')
    //         .then(response => {
    //             setContainerCards(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching data: ', error);
    //         });
    // };

    const fetchContainerCards = () => {
        axiosInstance.get('/containers')
            .then(response => {
                setContainerCards(response.data);
                console.log('container' , response.data)
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    };

    useEffect(fetchContainerCards, []);


    const handleAddClick = () => {
        setAddModalOpen(true);
    };

    const handleUpdateClick = (containerCard) => {
        setSelectedContainerCard(containerCard);
        setUpdateModalOpen(true);
    };

    // const handleAddContainerCard = (containerCard) => {
    //     setContainerCards(oldContainerCards => [...oldContainerCards, containerCard]);
    //     setAddModalOpen(false);
    // };

    // const handleUpdateContainerCard = (updatedContainerCard) => {
    //     setContainerCards(oldContainerCards => oldContainerCards.map(containerCard =>
    //         containerCard.id === updatedContainerCard.id ? updatedContainerCard : containerCard
    //     ));
    //     setUpdateModalOpen(false);
    //     setSelectedContainerCard(null);
    // };

    const handleAddContainerCard = (containerCard) => {
        const formData = new FormData();
        formData.append('brand', containerCard.brand);
        formData.append('name', containerCard.name);
        formData.append('volume', containerCard.volume);
        formData.append('material', containerCard.material);
        formData.append('type', containerCard.type); // ajout du type
        formData.append('transparent', containerCard.isTransparent);
        console.log('image : ')
        console.log(containerCard.image)
        if (containerCard.image instanceof File) {
            formData.append('file', containerCard.image);
        } else {
            console.error('containerCard.image is not a File:', containerCard.image);
        }

        // Use axios instance to send the request
        axios({
            method: 'post',
            url: 'https://backoffice.gourdefriendly.green/containers',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                console.log(response)
                fetchContainerCards();
                setAddModalOpen(false); // close the modal
            })
            .catch(error => {
                console.error('Error adding data: ', error);
            });
    };

    const handleUpdateContainerCard = (updatedContainerCard) => {
        const formData = new FormData();
        formData.append('brand', updatedContainerCard.brand);
        formData.append('name', updatedContainerCard.name);
        formData.append('volume', updatedContainerCard.volume);
        formData.append('material', updatedContainerCard.material);
        formData.append('type', updatedContainerCard.type); // ajout du type
        formData.append('transparent', updatedContainerCard.isTransparent); // ajout de la transparence
        
        
        if (updatedContainerCard.image) { 
            formData.append('brand', updatedContainerCard.brand);
            formData.append('name', updatedContainerCard.name);
            formData.append('volume', updatedContainerCard.volume);
            formData.append('material', updatedContainerCard.material);
            formData.append('file', updatedContainerCard.image);
            formData.append('type', updatedContainerCard.type); // ajout du type
            formData.append('transparent', updatedContainerCard.isTransparent); // ajout de la transparence
            
        }

        console.log(updatedContainerCard.type)

        
    
        // axios.put(`https://backoffice.gourdefriendly.green/containers/${updatedContainerCard.id}`, formData)
        //     .then(response => {
        //         console.log(response)
        //         fetchContainerCards();
        //         setUpdateModalOpen(false); // close the modal
        //     })
        //     .catch(error => {
        //         console.error('Error updating data: ', error);
        //     });

        axiosInstance.put(`/containers/${updatedContainerCard.id}`, formData)
    .then(response => {
        console.log(response);
        fetchContainerCards();
        setUpdateModalOpen(false); // close the modal
    })
    .catch(error => {
        console.error('Error updating data: ', error);
    });


    };
    

    const handleDeleteClick = (containerCardId) => {
        setContainerCardToDelete(containerCardId);
        setDeleteModalOpen(true);
    };

    // const handleDeleteConfirmClick = (containerCardId) => {
    //     axios.delete(`hhttps://backoffice.gourdefriendly.green/containers/${containerCardId}`)
    //         .then(response => {
    //             fetchContainerCards(); // Refresh the data
    //             setDeleteModalOpen(false);
    //         })
    //         .catch(error => {
    //             console.error('Error deleting data: ', error);
    //         });
    // };

    const handleDeleteConfirmClick = (containerCardId) => {
        axiosInstance.delete(`/containers/${containerCardId}`)
            .then(response => {
                fetchContainerCards(); // Refresh the data
                setDeleteModalOpen(false);
            })
            .catch(error => {
                console.error('Error deleting data: ', error);
            });
    };


    return (

        <div>
            <Navbar />
            <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleAddClick}
            >
                Ajouter
            </Button>
            <Grid container spacing={3}>
                {containerCards.map((containerCard) => (
                    <Grid item xs={12} sm={6} md={4} key={containerCard.id}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <img
                                    src={"https://backoffice.gourdefriendly.green/static/container_images/" + containerCard.image_url}
                                    alt={containerCard.brand}
                                    style={{
                                        marginRight: '1rem',
                                        maxWidth: '300px',
                                        height: 'auto'
                                    }}
                                />
                                <div>
                                    <Typography variant="h6">
                                        {containerCard.name}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Marque : {containerCard.brand}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Volume : {containerCard.volume}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Matériel : {containerCard.material}
                                    </Typography>
                                    <Typography variant="subtitle1">
                            Type : {containerCard.gourde ? 'Gourde' : containerCard.mug ? 'Mug' : containerCard.box ? 'Box' : 'N/A'}
                        </Typography>
                        <Typography variant="subtitle1">
                            Transparent : {containerCard.transparent ? 'Oui' : 'Non'}
                        </Typography>
                               
                                    <Button variant="contained" color="primary" onClick={() => handleUpdateClick(containerCard)}>
                                        Modifier
                                    </Button>
                                    <br></br>
                                    <br></br>
                                    <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(containerCard.id)}>
                                        Supprimer
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <AddContainerCardModal open={addModalOpen} onClose={() => setAddModalOpen(false)} onAdd={handleAddContainerCard} />
            <UpdateContainerCardModal open={updateModalOpen} onClose={() => setUpdateModalOpen(false)} onUpdate={handleUpdateContainerCard} containerCard={selectedContainerCard} />
            <DeleteConfirmationModal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={handleDeleteConfirmClick} containerCardId={containerCardToDelete} />
        </div>

    );

}

function UpdateContainerCardModal({ open, onClose, onUpdate, containerCard }) {
    const classes = useStyles();
    const [brand, setBrand] = useState(containerCard ? containerCard.brand : '');
    const [name, setName] = useState(containerCard ? containerCard.name : '');
    const [volume, setVolume] = useState(containerCard ? containerCard.volume : '');
    const [material, setMaterial] = useState(containerCard ? containerCard.material : '');
    const [image, setImage] = useState(null);
    const [type, setType] = useState('');
    const [isTransparent, setIsTransparent] = useState(containerCard ? containerCard.transparent : false);

    useEffect(() => {
        if (containerCard) {
            setBrand(containerCard.brand);
            setName(containerCard.name);
            setVolume(containerCard.volume);
            setMaterial(containerCard.material);
            setImage(containerCard.image);
            setIsTransparent(containerCard.transparent);
            if (containerCard.gourde) {
                setType('gourde');
            } else if (containerCard.mug) {
                setType('mug');
            } else if (containerCard.box) {
                setType('box');
            }
        }
        console.log("update : ", containerCard);
    }, [containerCard]);

    if (!containerCard) {
        return null;
    }

    const handleVolumeChange = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) { // Only set the value if it is a number
            setVolume(value);
        }
    };

    const handleUpdateClick = () => {
        let updatedContainerCard = {
            ...containerCard,
            name,
            brand,
            volume,
            material,
            image,
            type, // ajout du type
            isTransparent // ajout de la transparence
        };
        if (image) {
            updatedContainerCard.image = image;
        }
        onUpdate(updatedContainerCard);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.modal}>
                <Typography variant="h6" align="center" gutterBottom>
                    Modifier la fiche
                </Typography>
                <TextField type="file" label="Image" onChange={e => setImage(e.target.files[0])} fullWidth margin="normal" />
                <TextField label="Nom" value={name} onChange={e => setName(e.target.value)} fullWidth margin="normal" />
                <TextField label="Marque" value={brand} onChange={e => setBrand(e.target.value)} fullWidth margin="normal" />
                <TextField label="Volume" value={volume} onChange={handleVolumeChange} fullWidth margin="normal" />
                <TextField label="Matériau" value={material} onChange={e => setMaterial(e.target.value)} fullWidth margin="normal" />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="container-type-label">Type de contenant</InputLabel>
                    <Select
                        labelId="container-type-label"
                        value={type}
                        onChange={e => setType(e.target.value)}
                    >
                        <MenuItem value="gourde">Gourde</MenuItem>
                        <MenuItem value="mug">Mug</MenuItem>
                        <MenuItem value="box">Box</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isTransparent}
                            onChange={e => setIsTransparent(e.target.checked)}
                            name="isTransparent"
                        />
                    }
                    label="Transparent"
                />
                <Button variant="contained" color="primary" onClick={handleUpdateClick} fullWidth style={{ marginTop: '1rem' }}>
                    Mettre à jour
                </Button>
            </div>
        </Modal>
    );
}





function AddContainerCardModal({ open, onClose, onAdd }) {
    const classes = useStyles();
    const [brand, setBrand] = useState('');
    const [name, setName] = useState('');
    const [volume, setVolume] = useState('');
    const [material, setMaterial] = useState('');
    const [image, setImage] = useState(null);
    const [type, setType] = useState('');
    const [isTransparent, setIsTransparent] = useState(false);

    const handleVolumeChange = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) { // Only set the value if it is a number
            setVolume(value);
        }
    };

    const handleSaveClick = () => {
        const containerCard = {
            id: Math.random(),
            name,
            brand,
            volume,
            material,
            image,
            type, // ajout du type
            isTransparent // ajout de la transparence
        };
        onAdd(containerCard);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.modal}>
                <Typography variant="h6" align="center" gutterBottom>
                    Ajouter une nouvelle fiche
                </Typography>
                <TextField type="file" label="Image" onChange={e => setImage(e.target.files[0])} fullWidth margin="normal" />
                <TextField label="Nom" value={name} onChange={e => setName(e.target.value)} fullWidth margin="normal" />
                <TextField label="Marque" value={brand} onChange={e => setBrand(e.target.value)} fullWidth margin="normal" />
                <TextField label="Volume" value={volume} onChange={handleVolumeChange} fullWidth margin="normal" />
                <TextField label="Matériau" value={material} onChange={e => setMaterial(e.target.value)} fullWidth margin="normal" />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="container-type-label">Type de contenant</InputLabel>
                    <Select
                        labelId="container-type-label"
                        value={type}
                        onChange={e => setType(e.target.value)}
                    >
                        <MenuItem value="gourde">Gourde</MenuItem>
                        <MenuItem value="mug">Mug</MenuItem>
                        <MenuItem value="box">Box</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isTransparent}
                            onChange={e => setIsTransparent(e.target.checked)}
                            name="isTransparent"
                        />
                    }
                    label="Transparent"
                />
                <Button variant="contained" color="primary" onClick={handleSaveClick} fullWidth style={{ marginTop: '1rem' }}>
                    Sauvegarder
                </Button>
            </div>
        </Modal>
    );
}



export default ManageContainerCardsPage;
