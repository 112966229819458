import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import axiosInstance from '../../../services/axiosInstance';

const ItemType = 'CATEGORY';

const CategoryCardWrapper = styled.div`
  width: 150px;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const CategoryImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const CategoryName = styled.div`
  text-align: center;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permet aux catégories d'occuper plusieurs lignes si nécessaire */
`;

const DraggableCategory = ({ category, index, moveCategory }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      moveCategory(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: () => {
      return { id: category.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <CategoryCardWrapper ref={ref} style={{ opacity }}>
      <CategoryImage
        src={`https://backoffice.gourdefriendly.green/static/drink_images/${category.image_url}`}
        alt={category.name}
      />
      <CategoryName>{category.name}</CategoryName>
    </CategoryCardWrapper>
  );
};

const CategoryList = () => {
  const [mainCategories, setMainCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentDisposition, setCurrentDisposition] = useState([]);
  const [saveStatus, setSaveStatus] = useState(null);


  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const establishmentsResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/establishments-with-drinks');
  //       const establishments = establishmentsResponse.data;
        
  //       let allCategories = new Set();
  //       const addCategoryAndParents = (category) => {
  //         allCategories.add(category);
  //         if (category.parent) {
  //           addCategoryAndParents(category.parent);
  //         }
  //       };
  
  //       establishments.forEach(establishment => {
  //         establishment.drinks.forEach(drink => {
  //           drink.categories.forEach(category => {
  //             addCategoryAndParents(category);
  //           });
  //         });
  //       });
  
  //       const mainCategories = Array.from(allCategories).filter(category => !category.parent);
  
  //       const dispositionResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/category-disposition');
  //       const categoryOrder = dispositionResponse.data.order;
  //       const orderedCategories = categoryOrder.map(id => mainCategories.find(category => category.id === parseInt(id))).filter(Boolean);
  //       const unorderedCategories = mainCategories.filter(category => !categoryOrder.includes(String(category.id)));
  
  //       setMainCategories([...orderedCategories, ...unorderedCategories]);
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des catégories", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  
  //   fetchCategories();
  // }, []);


    useEffect(() => {
    const fetchCategories = async () => {
      try {
        // const establishmentsResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/establishments-with-drinks');
        const establishmentsResponse = await axiosInstance.get('/drinks/establishments-with-drinks');

        const establishments = establishmentsResponse.data;
  
        let categoriesById = {};
        const addCategoryAndParents = (category) => {
          categoriesById[category.id] = category;
          if (category.parent) {
            addCategoryAndParents(category.parent);
          }
        };
  
        establishments.forEach(establishment => {
          establishment.drinks.forEach(drink => {
            drink.categories.forEach(category => {
              addCategoryAndParents(category);
            });
          });
        });
  
        const mainCategories = Object.values(categoriesById).filter(category => !category.parent);
  
        // const dispositionResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/category-disposition');
        const dispositionResponse = await axiosInstance.get('/drinks/category-disposition');

        const categoryOrder = dispositionResponse.data.order;
  
        const orderedCategories = categoryOrder
          .map(id => mainCategories.find(category => category.id === parseInt(id)))
          .filter(Boolean);
  
        const orderedCategoryIds = orderedCategories.map(c => c.id);
        const unorderedCategories = mainCategories.filter(category => !orderedCategoryIds.includes(category.id));
  
        setMainCategories([...orderedCategories, ...unorderedCategories]);
      } catch (error) {
        console.error("Erreur lors de la récupération des catégories", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCategories();
  }, []);

  useEffect(() => {
    setCurrentDisposition(mainCategories);
  }, [mainCategories]);

  const moveCategory = (fromIndex, toIndex) => {
    const newCategories = Array.from(currentDisposition);
    const [movedItem] = newCategories.splice(fromIndex, 1);
    newCategories.splice(toIndex, 0, movedItem);
    setCurrentDisposition(newCategories);
  };

  // const updateCategoriesOnServer = async () => {
  //   console.log(currentDisposition)
  //   // try {
  //   //   const response = await axios.post('URL_DE_VOTRE_BACKEND', {
  //   //     categories: currentDisposition,
  //   //   });
  //   //   console.log('Mise à jour réussie', response.data);
  //   // } catch (error) {
  //   //   console.error('Erreur lors de la mise à jour des catégories', error);
  //   // }
  // };

  // const updateCategoriesOnServer = async () => {
  //   try {
  //     const categoryIds = currentDisposition.map(category => category.id);
  //     await axios.post('https://backoffice.gourdefriendly.green/drinks/save-category-disposition', {
  //       categories: categoryIds,
  //     });
  //     setSaveStatus('success');
  //     setTimeout(() => setSaveStatus(null), 3000); // Efface le message après 3 secondes
  //   } catch (error) {
  //     console.error('Error saving category disposition', error);
  //     setSaveStatus('error');
  //     setTimeout(() => setSaveStatus(null), 3000); // Efface le message après 3 secondes
  //   }
  // };

  const updateCategoriesOnServer = async () => {
    try {
      const categoryIds = currentDisposition.map(category => category.id);
      await axiosInstance.post('/drinks/save-category-disposition', {
        categories: categoryIds,
      });
      setSaveStatus('success');
      setTimeout(() => setSaveStatus(null), 3000);
    } catch (error) {
      console.error('Error saving category disposition', error);
      setSaveStatus('error');
      setTimeout(() => setSaveStatus(null), 3000);
    }
  };

  if (loading) {
    return <div>Chargement des catégories...</div>;
  }

   return (
    <DndProvider backend={HTML5Backend}>
      <CategoriesContainer>
        {currentDisposition.map((category, index) => (
          <DraggableCategory
            key={category.id}
            index={index}
            category={category}
            moveCategory={moveCategory}
          />
        ))}
      </CategoriesContainer>
      <button onClick={updateCategoriesOnServer}>Valider la disposition</button>
      {saveStatus === 'success' && <div>La disposition a été enregistrée avec succès !</div>}
      {saveStatus === 'error' && <div>Erreur lors de l'enregistrement de la disposition.</div>}
    </DndProvider>
  );
};

export default CategoryList;




