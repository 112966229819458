import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { Link as RouterLink , NavLink, useNavigate } from 'react-router-dom';
import useAdmin from '../utile/useAdmin';
const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
}));

function Navbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const isAdmin = useAdmin();

  const handleLogout = () => {
    // Effacer le token du localStorage
    // localStorage.removeItem('token');
    // localStorage.removeItem('user_id');
    // localStorage.removeItem('admin');
    // Rediriger vers la page de connexion
    localStorage.clear()
    navigate('/');
  };

  console.log('admin : ')
  console.log(localStorage.getItem('admin'))
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Gestionnaire CHR
        </Typography>
        <Button color="inherit" component={RouterLink} to="/accueil">
          Accueil
        </Button>
        {isAdmin && (
          <>
            <Button color="inherit" component={RouterLink} to="/admin/manage-container-cards">
              Gérer les contenants
            </Button>
            <Button color="inherit" component={RouterLink} to="/admin/manage-products-shop">
              Gérer les produits de la boutique 
            </Button>
            {/* <Button color="inherit" component={RouterLink} to="/admin/create_drink">
              Création fiche boisson
            </Button> */}
            <Button color="inherit" component={NavLink} to={`/admin/create-categories/`} activeClassName="active-link">
          Création des catégories
        </Button>
        <Button color="inherit" component={NavLink} to={`/admin/entreprise/`} activeClassName="active-link">
          Entreprises
        </Button>
            <Button color="inherit" component={RouterLink} to="/admin/gestion_stocks">
              Gestion des stocks des gourdes
            </Button>
            <Button color="inherit" component={RouterLink} to="/admin/gestion_stocks_mug">
              Gestion des stocks des mugs
            </Button>

          </>
        )}
        <Button color="inherit" onClick={handleLogout}>
          Déconnexion
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
