import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import axiosInstance from '../../../services/axiosInstance';

const ListWrapper = styled.div`
    width: 80%;
    margin: 2rem auto;
    background-color: #f7f7f7;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const DrinkCard = styled.div`
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
`;

const DrinkTitle = styled.h3`
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
`;

const DrinkDescription = styled.p`
    margin-bottom: 1rem;
`;

const CategoryTag = styled.span`
    background-color: #007BFF;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    margin-right: 0.5rem;
`;

// const CategoryTitle = styled.h2`
//     font-size: 1.5rem;
//     margin-top: 2rem;
// `;


const DrinkCardWrapper = styled(DrinkCard)`
    transition: transform 0.3s, box-shadow 0.3s;
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    }
`;

const DrinkName = styled(DrinkTitle)`
    font-size: 1.4rem;
    color: #2c3e50;
    margin-bottom: 0.8rem;
`;

const DrinkDetails = styled(DrinkDescription)`
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 1.2rem;
`;

const CategoryWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
`;

const IngredientList = styled.div`
    font-size: 0.9rem;
    color: #34495e;
`;

const IngredientListWrapper = styled.div`
    margin-top: 1rem;
`;

const IngredientTitle = styled.h4`
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #34495e;
    border-bottom: 2px solid #e74c3c;
    display: inline-block;
    padding-bottom: 0.3rem;
`;

const IngredientItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
`;

const IngredientIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
`;


const IngredientCardWrapper = styled.div`
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0.5rem 0 0;
    background-color: #f9f9f9;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f1f1f1;
    }
`;


const EditButton = styled.button`
    background-color: #f39c12;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #d35400;
    }
`;

const Select = styled.select`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const DeleteButton = styled.button`
    background-color: #e74c3c;
    color: white;
    padding: 0.3rem 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
        background-color: #c0392b;
    }
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
`;

const Input = styled.input`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
`;

const CategoryTitle = styled.h2`
    font-size: 2rem;  // Augmentez la taille de la police
    margin-top: 2.5rem;  // Augmentez l'espacement supérieur
    margin-bottom: 1rem;  // Ajoutez de l'espacement en bas
    font-weight: bold;  // Rendez le texte en gras
    text-transform: uppercase;  // Mettez le texte en majuscules
    color: #2c3e50;  // Vous pouvez ajuster la couleur si vous le souhaitez
`;

const DrinkImage = styled.img`
    width: 200px;              // Largeur maximale de l'image
    height: 200px;             // Hauteur maximale de l'image
    object-fit: cover;         // Pour conserver les proportions de l'image
    border-radius: 8px;        // Pour rendre l'image légèrement arrondie
    margin-bottom: 1rem;       // Espacement en dessous de l'image
`;


const groupDrinksByCategory = (drinks, categories) => {
    const groupedDrinks = {};
    categories.forEach(category => {
        groupedDrinks[category.name] = drinks.filter(drink => drink.category_id === category.id);
    });
    return groupedDrinks;
};

const DrinksList = () => {
    const [drinks, setDrinks] = useState([]);
    const [editingDrinkId, setEditingDrinkId] = useState(null);
    const [availableIngredients, setAvailableIngredients] = useState([]);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);

    const groupedDrinks = groupDrinksByCategory(drinks, availableCategories);


    // useEffect(() => {
    //     // Fetch initial data
    //     const fetchAllData = async () => {
    //         try {
    //             const drinksResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/drinks');
    //             const ingredientsResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/ingredients');
    //             const brandsResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/brands');
    //             const categoriesResponse = await axios.get('https://backoffice.gourdefriendly.green/drinks/drink-categories');

    //             setDrinks(drinksResponse.data);
    //             setAvailableIngredients(ingredientsResponse.data);
    //             setAvailableBrands(brandsResponse.data);
    //             setAvailableCategories(categoriesResponse.data);
    //         } catch (error) {
    //             console.error("Error fetching data", error);
    //         }
    //     };

    //     fetchAllData();
    // }, []);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const drinksResponse = await axiosInstance.get('/drinks/drinks');
                const ingredientsResponse = await axiosInstance.get('/drinks/ingredients');
                const brandsResponse = await axiosInstance.get('/drinks/brands');
                const categoriesResponse = await axiosInstance.get('/drinks/drink-categories');
    
                setDrinks(drinksResponse.data);
                setAvailableIngredients(ingredientsResponse.data);
                setAvailableBrands(brandsResponse.data);
                setAvailableCategories(categoriesResponse.data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
    
        fetchAllData();
    }, []);

    const handleDrinkChange = (drinkId, fieldName, value) => {
        setDrinks(prevDrinks =>
            prevDrinks.map(d =>
                d.id === drinkId ? { ...d, [fieldName]: value } : d
            )
        );
    };

    const handleUpdateDrink = async (drinkId) => {
        const drinkToUpdate = drinks.find(d => d.id === drinkId);

        const formData = new FormData();
        formData.append('nom', drinkToUpdate.nom);
        formData.append('description', drinkToUpdate.description);
        formData.append('marque_id', drinkToUpdate.marque_id);
        formData.append('ingredients', JSON.stringify(drinkToUpdate.ingredients)); // Vous pouvez convertir le tableau d'ingrédients en chaîne JSON
        formData.append('categories', JSON.stringify(drinkToUpdate.categories.map(category => category.id)));


        


        // Si vous avez un champ pour l'image dans l'état de la boisson, ajoutez-le également :
        if (drinkToUpdate.image) {
            formData.append('image', drinkToUpdate.image);
        }

        console.log(`https://backoffice.gourdefriendly.green/drinks/drinks/${drinkId}`)
        console.log(JSON.stringify(drinkToUpdate.categories))

        // try {
        //     const response = await axios.put(
        //         `https://backoffice.gourdefriendly.green/drinks/drinks/${drinkId}`,
        //         formData,
        //         { headers: { 'Content-Type': 'multipart/form-data' } }
        //     );

        //     if (response.status === 200) {
        //         alert('Drink updated successfully!');
        //         setEditingDrinkId(null);  // Exit editing mode
        //     }
        // } catch (error) {
        //     console.error("Error updating drink", error);
        //     // alert('Error updating the drink');
        // }

        try {
            const response = await axiosInstance.put(
                `/drinks/drinks/${drinkId}`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
    
            if (response.status === 200) {
                alert('Drink updated successfully!');
                setEditingDrinkId(null);  // Exit editing mode
            }
        } catch (error) {
            console.error("Error updating drink", error);
            // alert('Error updating the drink');
        }
    };


    const handleAddIngredient = (drinkId, ingredientId) => {
        // Trouvez l'objet ingrédient complet en utilisant l'ID.
        const ingredientToAdd = availableIngredients.find(ingredient => ingredient.id === parseInt(ingredientId));

        // Si l'ingrédient est trouvé, ajoutez-le à la liste d'ingrédients de la boisson.
        if (ingredientToAdd) {
            setDrinks(prevDrinks =>
                prevDrinks.map(d =>
                    d.id === drinkId ? { ...d, ingredients: [...d.ingredients, ingredientToAdd] } : d
                )
            );
        }
    };


    const handleRemoveIngredient = (drinkId, ingredientId) => {
        setDrinks(prevDrinks =>
            prevDrinks.map(d =>
                d.id === drinkId ? { ...d, ingredients: d.ingredients.filter(i => i.id !== ingredientId) } : d
            )
        );
    };

    const handleImageChange = (drinkId, file) => {
        setDrinks(prevDrinks =>
            prevDrinks.map(d =>
                d.id === drinkId ? { ...d, image: file } : d
            )
        );
    };

    const handleCategoryChange = (drinkId, newCategories) => {
        setDrinks(prevDrinks =>
            prevDrinks.map(d =>
                d.id === drinkId ? { ...d, categories: newCategories } : d
            )
        );
    };

    const handleAddCategory = (drinkId, categoryId) => {
        const categoryToAdd = availableCategories.find(c => c.id === categoryId);

        if (categoryToAdd) {
            setDrinks(prevDrinks =>
                prevDrinks.map(d =>
                    d.id === drinkId ? { ...d, categories: [...d.categories, categoryToAdd] } : d
                )
            );
        }
    };

    const handleRemoveCategory = (drinkId, categoryId) => {
        setDrinks(prevDrinks =>
            prevDrinks.map(d =>
                d.id === drinkId ? { ...d, categories: d.categories.filter(c => c.id !== categoryId) } : d
            )
        );
    };



    const BASE_IMAGE_URL = "https://backoffice.gourdefriendly.green/static/drink_images/";

    return (
        <ListWrapper>
            {drinks.map(drink => (
                <DrinkCardWrapper key={drink.id}>
                    {editingDrinkId === drink.id ? (
                        <>
                            <Label>Nom:</Label>
                            <Input
                                type="text"
                                value={drink.nom}
                                onChange={e => handleDrinkChange(drink.id, 'nom', e.target.value)}
                            />


                            <Label>Image:</Label>
                            <Input
                                type="file"
                                onChange={e => handleImageChange(drink.id, e.target.files[0])}
                            />

                            <Label>Description:</Label>
                            <Textarea
                                value={drink.description}
                                onChange={e => handleDrinkChange(drink.id, 'description', e.target.value)}
                            />

                            <Label>Marque:</Label>
                            <Select
                                value={drink.marque_id}
                                onChange={e => handleDrinkChange(drink.id, 'marque_id', e.target.value)}
                            >
                                <option value="">Aucune marque</option>

                                {availableBrands.map(brand => (
                                    <option key={brand.id} value={brand.id}>{brand.name}</option>
                                ))}
                            </Select>
                            <Label>Catégories:</Label>
                            {drink.categories.map(category => (
                                <IngredientCardWrapper key={category.id}>
                                    {category.name}
                                    <DeleteButton onClick={() => handleRemoveCategory(drink.id, category.id)}>
                                        Supprimer
                                    </DeleteButton>
                                </IngredientCardWrapper>
                            ))}
                            <Select id="new-category">
                                {availableCategories.map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </Select>
                            <button type="button" onClick={() => handleAddCategory(drink.id, Number(document.getElementById('new-category').value))}>
                                Ajouter la catégorie
                            </button>


                            <Label>Ingrédients:</Label>
                            {drink.ingredients.map(ingredient => (
                                <IngredientCardWrapper key={ingredient.id}>
                                    {ingredient.nom}
                                    <DeleteButton onClick={() => handleRemoveIngredient(drink.id, ingredient.id)}>
                                        Supprimer
                                    </DeleteButton>
                                </IngredientCardWrapper>
                            ))}
                            <Select onChange={e => handleAddIngredient(drink.id, e.target.value)}>
                                <option value="">Ajouter un ingrédient</option>
                                {availableIngredients.map(ingredient => (
                                    <option key={ingredient.id} value={ingredient.id}>{ingredient.nom}</option>
                                ))}
                            </Select>
                            <EditButton onClick={() => handleUpdateDrink(drink.id)}>Enregistrer</EditButton>
                        </>
                    ) : (
                        <>
                            <DrinkName>{drink.nom}</DrinkName>
                            <DrinkImage src={`${BASE_IMAGE_URL}${drink.url_image}`} alt={drink.nom} />
                            <DrinkDetails>{drink.description}</DrinkDetails>
                            <IngredientListWrapper>
                                <IngredientTitle>Ingrédients :</IngredientTitle>
                                {drink.ingredients.map(ingredient => (
                                    <IngredientCardWrapper key={ingredient.id}>
                                        {ingredient.nom}
                                    </IngredientCardWrapper>
                                ))}
                            </IngredientListWrapper>
                            <EditButton onClick={() => setEditingDrinkId(drink.id)}>Modifier</EditButton>
                        </>
                    )}
                </DrinkCardWrapper>
            ))}
        </ListWrapper>
    );
}

export default DrinksList;
