import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Page non trouvée</h2>
      <p>Désolé, la page que vous recherchez n'existe pas ou a été déplacée.</p>
      <Link to="/">Retour à la page d'accueil</Link>
    </div>
  );
}

export default NotFound;
