import React, { useState, useEffect } from 'react';
import axios from 'axios';

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginBottom : 100
  },
  selectedCategories: {
    margin: '10px 0',
    padding: '10px',
    backgroundColor: '#e9f5ec',
    borderRadius: '4px',
  },
  categoryItem: {
    border: '1px solid #007bff',
    padding: '10px',
    margin: '5px 0',
    borderRadius: '4px',
    backgroundColor: '#f0f8ff',
  },
  subCategoryItem: {
    border: '1px dashed #28a745',
    padding: '10px',
    margin: '5px 0 5px 20px',
    borderRadius: '4px',
    backgroundColor: '#e9f5ec',
  },
  checkbox: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  categoryLabel: {
    fontWeight: 'bold',
    marginLeft: '5px',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '20px',
  }
};

const CategorySelector = () => {
  const [categories, setCategories] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    // Récupération initiale des catégories
    axios.get('https://backoffice.gourdefriendly.green/categories_easy_drinks/categories')
      .then(response => {
        setCategories(response.data);
        return response.data;
      })
      .then(fetchedCategories => {
        // Récupération de la disposition actuelle des catégories
        axios.get('https://backoffice.gourdefriendly.green/drinks/category-disposition')
          .then(response => {
            const order = response.data.order.map(Number); // Assurez-vous que les IDs sont des nombres
            // Filtrez pour ne garder que les IDs qui existent dans les catégories récupérées
            const validIds = order.filter(id => fetchedCategories.some(cat => cat.id === id || (cat.subCategories && cat.subCategories.some(subCat => subCat.id === id))));
            setSelectedIds(validIds);
          })
          .catch(error => {
            console.error('Erreur lors de la récupération de la disposition des catégories', error);
          });
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des catégories', error);
      });
  }, []);

  const handleSelectCategory = (id) => {
    setSelectedIds(prevIds => {
      // Si l'ID est déjà sélectionné, retirez-le
      if (prevIds.includes(id)) {
        return prevIds.filter(prevId => prevId !== id);
      } else {
        // Sinon, ajoutez l'ID à la fin du tableau
        return [...prevIds, id];
      }
    });
  };
  

  const getSelectedCategoryNames = () => {
    let selectedNames = selectedIds.map(id => {
      let name = '';
      const findName = (categories) => {
        for (let category of categories) {
          if (category.id === id) {
            name = category.name;
            return;
          }
          if (category.subCategories && category.subCategories.length > 0) {
            findName(category.subCategories);
          }
        }
      };
      findName(categories);
      return name;
    });
    return selectedNames.join(', ');
  };
  

  const renderCategory = (category, isSubCategory = false) => (
    <div key={category.id} style={isSubCategory ? styles.subCategoryItem : styles.categoryItem}>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          style={styles.checkbox}
          checked={selectedIds.includes(category.id)}
          onChange={() => handleSelectCategory(category.id)}
        />
        <span style={styles.categoryLabel}>{category.name}</span>
      </label>
      {category.subCategories && category.subCategories.length > 0 && (
        <div>
          {category.subCategories.map(subCategory => renderCategory(subCategory, true))}
        </div>
      )}
    </div>
  );

  const handleSubmit = () => {
    // Envoi des IDs sélectionnés au serveur

    axios.post('https://backoffice.gourdefriendly.green/drinks/save-category-disposition', { categories: selectedIds })
      .then(response => {

        console.log('Disposition enregistrée avec succès');
      })
      .catch(error => {
        console.error('Erreur lors de l\'enregistrement de la disposition', error);
      });
  };

  return (
    <div style={styles.container}>
      <h2>Sélectionnez des catégories</h2>
      <div style={styles.selectedCategories}>
        <strong>Catégories sélectionnées :</strong> {getSelectedCategoryNames()}
      </div>
      {categories.map(category => renderCategory(category))}
      <button style={styles.submitButton} onClick={handleSubmit}>
        Envoyer les sélections
      </button>
    </div>
  );
};

export default CategorySelector;
