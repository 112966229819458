// CreateIngredientForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import axiosInstance from '../../../services/axiosInstance';

const FormWrapper = styled.div`
    width: 80%;
    margin: 2rem auto;
    background-color: #f7f7f7;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
`;

const FormField = styled.div`
    margin-bottom: 1rem;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;

const Input = styled.input`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
`;

const SubmitButton = styled.button`
    background-color: #007BFF;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }
`;

const IngredientImage = styled.img`
    max-width: 150px;   // Largeur maximale
    max-height: 150px;  // Hauteur maximale
    margin: 10px;       // Marge autour de l'image
    border-radius: 8px; // Coins arrondis pour une belle apparence
`;

const CreateIngredientForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        nom: '',
        marque_id: '',
        // ... autres champs nécessaires pour les ingrédients
    });
    const [ingredients, setIngredients] = useState([]);
    const [editingIngredient, setEditingIngredient] = useState(null);

    const [brands, setBrands] = useState([]);

    // useEffect(() => {
    //     const fetchBrands = async () => {
    //         try {
    //             const response = await axios.get('https://backoffice.gourdefriendly.green/drinks/brands');
    //             setBrands(response.data);
    //         } catch (error) {
    //             console.error("Erreur lors de la récupération des marques", error);
    //         }
    //     };

    //     fetchBrands();
    // }, []);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axiosInstance.get('/drinks/brands');
                setBrands(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des marques", error);
            }
        };
    
        fetchBrands();
    }, []);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const startEditing = (ingredient) => {
        setFormData({
            nom: ingredient.nom
            // ... autres champs si nécessaire
        });
        setEditingIngredient(ingredient);
    }




    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSend = new FormData();
        dataToSend.append('nom', formData.nom);
        if (formData.image) {
            dataToSend.append('image', formData.image);
        }

        dataToSend.append('marque_id', formData.marque_id);



        try {
            if (editingIngredient) {
                // const response = await axios.put(`https://backoffice.gourdefriendly.green/drinks/ingredients/${editingIngredient.id}`, dataToSend);
                const response = await axiosInstance.put(`/drinks/ingredients/${editingIngredient.id}`, dataToSend);

                if (response.status === 200) {
                    alert("Ingrédient mis à jour avec succès !");
                }
            } else {
                try {
                    // const response = await axios.post('https://backoffice.gourdefriendly.green/drinks/ingredients', dataToSend);
                    const response = await axiosInstance.post('/drinks/ingredients', dataToSend);

                    if (response.status === 201) {
                        alert('Ingrédient créé avec succès!');
                        onClose(); // Ferme la fenêtre modale
                    }
                } catch (error) {
                    console.error("Erreur lors de la création de l'ingrédient", error);
                    alert("Erreur lors de la création de l'ingrédient");
                }
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'ingrédient", error);
            alert("Erreur lors de la mise à jour de l'ingrédient");
        }
    }


    // useEffect(() => {
    //     const fetchIngredients = async () => {
    //         try {
    //             const response = await axios.get('https://backoffice.gourdefriendly.green/drinks/ingredients');
    //             setIngredients(response.data);
    //         } catch (error) {
    //             console.error('Erreur lors de la récupération des ingrédients', error);
    //         }
    //     };

    //     fetchIngredients();
    // }, []);

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                const response = await axiosInstance.get('/drinks/ingredients');
                setIngredients(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des ingrédients', error);
            }
        };
    
        fetchIngredients();
    }, []);

    const handleFileChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            image: e.target.files[0]
        }));
    };


    return (
        <FormWrapper>
            <Title>{editingIngredient ? "Modifier l'ingrédient existant" : "Créer un nouvel ingrédient"}</Title>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Label>Nom:</Label>
                    <Input type="text" name="nom" value={formData.nom} onChange={handleChange} required />
                </FormField>
                <FormField>
                    <Label>Image de l'ingrédient:</Label>
                    <Input type="file" onChange={handleFileChange} />
                </FormField>
                <FormField>
                    <Label>Marque :</Label>
                    <select name="marque_id" value={formData.marque_id} onChange={handleChange}>
                        <option value="">Aucune marque</option>
                        {brands.map(brand => (
                            <option key={brand.id} value={brand.id}>
                                {brand.name}
                            </option>
                        ))}
                    </select>
                </FormField>

                {/* ... autres champs nécessaires pour les ingrédients */}
                <FormField>
                    <SubmitButton type="submit">{editingIngredient ? "Mettre à jour l'ingrédient" : "Ajouter l'ingrédient"}</SubmitButton>
                </FormField>
            </form>

            {editingIngredient && <button onClick={() => setEditingIngredient(null)}>Annuler la modification</button>}

            <h3>Ingrédients ajoutés :</h3>
            <ul>
                {ingredients.map(ingredient => (
                    <li key={ingredient.id}>
                        <IngredientImage src={`https://backoffice.gourdefriendly.green/static/drink_images/${ingredient.image_url}`} alt={ingredient.name} />

                        {ingredient.nom}
                        <button onClick={() => startEditing(ingredient)}>Modifier</button>
                    </li>
                ))}

            </ul>
        </FormWrapper>
    );
}

export default CreateIngredientForm;
