// views/adminEstablissement/ManageContainerCardsPage.js

import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Grid, makeStyles, Typography, Modal, TextField  , Select , InputLabel, MenuItem  , FormControl} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import axiosInstance from '../../services/axiosInstance';




const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row', // change from 'column' to 'row'
        justifyContent: 'space-around', // change from 'space-around' to 'flex-start'
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    addButton: {
        margin: theme.spacing(2),
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },

    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        borderRadius: '10px',
        outline: 'none',
        boxShadow: theme.shadows[5],
    },
}));

function DeleteConfirmationModal({ open, onClose, onConfirm, containerCardId }) {
    const classes = useStyles();

    const handleConfirmClick = () => {
        onConfirm(containerCardId);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.modal}>
                <Typography variant="h6" align="center" gutterBottom>
                    Êtes-vous sûr de vouloir supprimer ce produit ?
                </Typography>
                <Button variant="contained" color="primary" onClick={handleConfirmClick} fullWidth style={{ marginTop: '1rem' }}>
                    Oui
                </Button>
                <Button variant="contained" color="secondary" onClick={onClose} fullWidth style={{ marginTop: '1rem' }}>
                    Non
                </Button>
            </div>
        </Modal>
    );
}

function ManageProductShop() {
    const classes = useStyles();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [containerCardToDelete, setContainerCardToDelete] = useState(null);

    // const [containerCards, setContainerCards] = useState([
    //     { id: 1, brand: 'SportBrand', volume: '500ml', material: 'Plastic' },
    //     { id: 2, brand: 'EcoBrand', volume: '1L', material: 'Metal' },
    // ]);
    const [containerCards, setContainerCards] = useState([]);



    const [addModalOpen, setAddModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedContainerCard, setSelectedContainerCard] = useState(null);
    const [categoriesShop, setCategories] = useState([]);

    const fetchCategories = () => {
        axiosInstance.get('shop/categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories: ', error);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);


    const fetchContainerCards = () => {
        axiosInstance.get('shop/products')
            .then(response => {
                setContainerCards(response.data);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    };

    useEffect(fetchContainerCards, []);


    const handleAddClick = () => {
        setAddModalOpen(true);
    };

    const handleUpdateClick = (containerCard) => {
        setSelectedContainerCard(containerCard);
        setUpdateModalOpen(true);
    };


    const handleAddContainerCard = (containerCard) => {
        const formData = new FormData();
        formData.append('brand', containerCard.brand);
        formData.append('name', containerCard.name);
        formData.append('description', containerCard.description);
        formData.append('volume', containerCard.volume);
        formData.append('material', containerCard.material);
        formData.append('price', containerCard.price);
        formData.append('category_id', containerCard.category);
        console.log('image : ')
        console.log(containerCard.image)
        if (containerCard.image instanceof File) {
            formData.append('file', containerCard.image);
        } else {
            console.error('containerCard.image is not a File:', containerCard.image);
        }

       console.log("")

        // Use axios instance to send the request
        axios({
            method: 'post',
            url: 'https://backoffice.gourdefriendly.green/shop/products',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                console.log(response)
                fetchContainerCards();
                setAddModalOpen(false); // close the modal
            })
            .catch(error => {
                console.error('Error adding data: ', error);
            });
    };

    const handleUpdateContainerCard = (updatedContainerCard) => {
        const formData = new FormData();
        formData.append('brand', updatedContainerCard.brand);
        formData.append('name', updatedContainerCard.name);
        formData.append('description', updatedContainerCard.description);
        formData.append('volume', updatedContainerCard.volume);
        formData.append('material', updatedContainerCard.material);
        formData.append('price', updatedContainerCard.price);
        formData.append('category_id', updatedContainerCard.category);

        if (updatedContainerCard.image) {
            formData.append('brand', updatedContainerCard.brand);
            formData.append('name', updatedContainerCard.name);
            formData.append('volume', updatedContainerCard.volume);
            formData.append('material', updatedContainerCard.material);
            formData.append('file', updatedContainerCard.image);
        }





        axiosInstance.put(`shop/products/${updatedContainerCard.id}`, formData)
            .then(response => {
                console.log(response);
                fetchContainerCards();
                setUpdateModalOpen(false); // close the modal
            })
            .catch(error => {
                console.error('Error updating data: ', error);
            });


    };


    const handleDeleteClick = (containerCardId) => {
        setContainerCardToDelete(containerCardId);
        setDeleteModalOpen(true);
    };



    const handleDeleteConfirmClick = (containerCardId) => {
        axiosInstance.delete(`shop/products/${containerCardId}`)
            .then(response => {
                fetchContainerCards(); // Refresh the data
                setDeleteModalOpen(false);
            })
            .catch(error => {
                console.error('Error deleting data: ', error);
            });
    };


    return (

        <div>
            <Navbar />
            <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleAddClick}
            >
                Ajouter
            </Button>
            <Grid container spacing={3}>
                {containerCards.map((containerCard) => (
                    <Grid item xs={12} sm={6} md={4} key={containerCard.id}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <img
                                    src={"https://backoffice.gourdefriendly.green/static/container_images/" + containerCard.image_url}
                                    alt={containerCard.brand}
                                    style={{
                                        marginRight: '1rem',
                                        maxWidth: '300px',
                                        height: 'auto'
                                    }}
                                />
                                <div>
                                    <Typography variant="h6">
                                        {containerCard.name}
                                    </Typography>
                                    <Typography variant="h6">
                                        Description : {containerCard.description}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Marque : {containerCard.brand}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Volume : {containerCard.volume}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Matériel : {containerCard.material}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Prix : {containerCard.price} €
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Catégorie : {containerCard.category_name}
                                    </Typography>
                                    <Button variant="contained" color="primary" onClick={() => handleUpdateClick(containerCard)}>
                                        Modifier
                                    </Button>
                                    <br></br>
                                    <br></br>
                                    <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(containerCard.id)}>
                                        Supprimer
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <AddContainerCardModal open={addModalOpen} onClose={() => setAddModalOpen(false)} onAdd={handleAddContainerCard} categoriesShop={categoriesShop}/>
            <UpdateContainerCardModal open={updateModalOpen} onClose={() => setUpdateModalOpen(false)} onUpdate={handleUpdateContainerCard} categoriesShop={categoriesShop} containerCard={selectedContainerCard} />
            <DeleteConfirmationModal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={handleDeleteConfirmClick} containerCardId={containerCardToDelete} />
        </div>

    );

}

function UpdateContainerCardModal({ open, onClose, onUpdate, containerCard , categoriesShop }) {
    const classes = useStyles();
    const [brand, setBrand] = useState(containerCard ? containerCard.brand : '');
    const [name, setName] = useState(containerCard ? containerCard.name : '');
    const [description, setDescription] = useState(containerCard ? containerCard.description : '');

    const [volume, setVolume] = useState(containerCard ? containerCard.volume : '');
    const [material, setMaterial] = useState(containerCard ? containerCard.material : '');
    const [image, setImage] = useState(null);
    const [price, setPrice] = useState(containerCard ? containerCard.price : '');
    const [category, setCategory] = useState(containerCard ? containerCard.category_id : '');
    useEffect(() => {
        if (containerCard) {
            setBrand(containerCard.brand);
            setName(containerCard.name)
            setDescription(containerCard.description)
            setVolume(containerCard.volume);
            setMaterial(containerCard.material);
            setImage(containerCard.image)
        }
    }, [containerCard]);

    // Move this condition here
    if (!containerCard) {
        return null;
    }

    const handleUpdateClick = () => {
        let updatedContainerCard = {
            ...containerCard,
            name,
            description,
            brand,
            volume,
            material,
            price,
            category

        };
        if (image) {
            updatedContainerCard.image = image; // only include the image if one is selected
        }
        onUpdate(updatedContainerCard);
    };



    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.modal}>
                <Typography variant="h6" align="center" gutterBottom>
                    Modifier la fiche
                </Typography>
                <TextField type="file" label="Image" onChange={e => setImage(e.target.files[0])} fullWidth margin="normal" />
                <TextField label="Nom" value={name} onChange={e => setName(e.target.value)} fullWidth margin="normal" />
                <TextField label="Description" value={description} onChange={e => setDescription(e.target.value)} fullWidth margin="normal" />

                <TextField label="Marque" value={brand} onChange={e => setBrand(e.target.value)} fullWidth margin="normal" />
                <TextField label="Volume" value={volume} onChange={e => setVolume(e.target.value)} fullWidth margin="normal" />
                <TextField label="Matériau" value={material} onChange={e => setMaterial(e.target.value)} fullWidth margin="normal" />
                <TextField label="Prix" value={price} onChange={e => setPrice(e.target.value)} fullWidth margin="normal" />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Catégorie</InputLabel>
                    <Select
                        value={category}
                        onChange={e => setCategory(e.target.value)}
                    >
                        {categoriesShop.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>                <Button variant="contained" color="primary" onClick={handleUpdateClick} fullWidth style={{ marginTop: '1rem' }}>
                    Mettre à jour
                </Button>
            </div>
        </Modal>
    );
}



function AddContainerCardModal({ open, onClose, onAdd , categoriesShop }) {
    const classes = useStyles();
    const [brand, setBrand] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [volume, setVolume] = useState('');
    const [material, setMaterial] = useState('');
    const [image, setImage] = useState(null);
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');


    const handleSaveClick = () => {
        const containerCard = {
            id: Math.random(), // Replace this with a proper id from your backend
            name,
            description,
            brand,
            volume,
            material,
            image, // include the image in the new container card
            price,
            category
        };
        onAdd(containerCard);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.modal}>
                <Typography variant="h6" align="center" gutterBottom>
                    Ajouter une nouvelle fiche
                </Typography>
                <TextField type="file" label="Image" onChange={e => setImage(e.target.files[0])} fullWidth margin="normal" />
                <TextField label="Nom" value={name} onChange={e => setName(e.target.value)} fullWidth margin="normal" />
                <TextField label="Description" value={description} onChange={e => setDescription(e.target.value)} fullWidth margin="normal" />

                <TextField label="Marque" value={brand} onChange={e => setBrand(e.target.value)} fullWidth margin="normal" />
                <TextField label="Volume" value={volume} onChange={e => setVolume(e.target.value)} fullWidth margin="normal" />
                <TextField label="Matériau" value={material} onChange={e => setMaterial(e.target.value)} fullWidth margin="normal" />
                <TextField label="Prix" value={price} onChange={e => setPrice(e.target.value)} fullWidth margin="normal" />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Catégorie</InputLabel>
                    <Select
                        value={category}
                        onChange={e => setCategory(e.target.value)}
                    >
                        {categoriesShop.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>                <Button variant="contained" color="primary" onClick={handleSaveClick} fullWidth style={{ marginTop: '1rem' }}>
                    Sauvegarder
                </Button>
            </div>
        </Modal>
    );
}



export default ManageProductShop;
