import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';
function PendingGourdeOrders({Refresh , setRefresh , onSelectEtab}) {
    const [orders, setOrders] = useState([]);
    const [totalGourdes, setTotalGourdes] = useState(0);

    // useEffect(() => {
    //     fetch(`https://backoffice.gourdefriendly.green/stock/gourde-orders/pending`)
    //         .then(response => response.json())
    //         .then(data => {
    //             setOrders(data.orders);
    //             setTotalGourdes(data.total_gourdes);
    //             setRefresh(false)
    //         })
    //         .catch(error => {
    //             console.error("Erreur lors de la récupération des commandes en attente:", error);
    //         });
    // }, [Refresh]);

    useEffect(() => {
        axiosInstance.get('/stock/gourde-orders/pending')
            .then(response => {
                setOrders(response.data.orders);
                setTotalGourdes(response.data.total_gourdes);
                setRefresh(false);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des commandes en attente:", error);
            });
    }, [Refresh]);

    const handleRowClick = (etabId) => {
        // onSelectEtab(etabId);
    };


    return (
        <div className="pending-gourde-orders">
            <h2>Toutes les commandes en attente</h2>
            <p>Total des gourdes commandées : {totalGourdes}</p>
            <table>
                <thead>
                    <tr>
                        <th>ID Commande</th>
                        <th>Nom établissement</th>
                        <th>Ville</th>
                        <th>Code INSEE</th>
                        <th>Adresse</th>
                        <th>Quantité commandée</th>
                        <th>Date de commande</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.order_id } onClick={() => handleRowClick(order.etablissement_id)}>
                            <td>{order.order_id}</td>
                            <td>{order.etablissement_name}</td>
                            <td>{order.etablissement_city}</td>
                            <td>{order.etablissement_insee}</td>
                            <td>{order.etablissement_address}</td>
                            <td>{order.quantity_ordered}</td>
                            <td>{order.date_ordered}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PendingGourdeOrders;
