import React, { useState, useEffect } from 'react';
import axios from 'axios';  // Assurez-vous d'avoir installé axios
import styled from 'styled-components';
import axiosInstance from '../../../services/axiosInstance';



import CreateBrandForm from './CreateBrandForm';
import CreateCategoryForm from './CreateCategoryForm';
import CreateIngredientForm from './CreateIngredientForm';
import DrinksList from './DrinksList';
import CategoryList from './CategoryList';
import Navbar from '../../../components/Navbar';

const FormWrapper = styled.div`
    width: 80%;
    margin: 2rem auto;
    background-color: #f7f7f7;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
`;

const FormField = styled.div`
    margin-bottom: 1rem;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;

const Input = styled.input`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
`;

const SubmitButton = styled.button`
    background-color: #007BFF;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }
`;


const IngredientCard = styled.div`
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    background-color: #f7f7f7;

    &:hover {
        background-color: #e6e6e6;
    }

    transition: background-color 0.2s;
`;

const RemoveButton = styled.button`
    margin-left: 0.5rem;
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
        color: darkred;
    }
`;

const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;


const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                backgroundColor: '#fff',
                padding: '2rem',
                borderRadius: '8px',
                width: '50%',
                maxHeight: '80%',
                overflowY: 'auto'
            }}>
                <button onClick={onClose} style={{ float: 'right' }}>Fermer</button>
                {children}
            </div>
        </div>
    );
}




const CreateDrinkForm = () => {

    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const [isBrandModalOpen, setBrandModalOpen] = useState(false);
    const [isIngredientModalOpen, setIngredientModalOpen] = useState(false);

    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    const [availableIngredients, setAvailableIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [mainCategories, setMainCategories] = useState([]);




    useEffect(() => {
        // Charger les catégories depuis le backend
        // const fetchCategories = async () => {
        //     try {
        //         const response = await axios.get('https://backoffice.gourdefriendly.green/drinks/drink-categories');
        //         setCategories(response.data);
        //     } catch (error) {
        //         console.error("Erreur lors de la récupération des catégories", error);
        //     }
        // };

        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/drinks/drink-categories');
                setCategories(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des catégories", error);
            }
        };

        // Charger les marques depuis le backend
        // const fetchBrands = async () => {
        //     try {
        //         const response = await axios.get('https://backoffice.gourdefriendly.green/drinks/brands');
        //         setBrands(response.data);
        //     } catch (error) {
        //         console.error("Erreur lors de la récupération des marques", error);
        //     }
        // };

        const fetchBrands = async () => {
            try {
                const response = await axiosInstance.get('/drinks/brands');
                setBrands(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des marques", error);
            }
        };



        fetchCategories();
        fetchBrands();
    }, []);

    // useEffect(() => {
    //     const fetchIngredients = async () => {
    //         try {
    //             const response = await axios.get('https://backoffice.gourdefriendly.green/drinks/ingredients');
    //             setAvailableIngredients(response.data);
    //         } catch (error) {
    //             console.error("Erreur lors de la récupération des ingrédients", error);
    //         }
    //     };

    //     fetchIngredients();
    // }, []);

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                const response = await axiosInstance.get('/drinks/ingredients');
                setAvailableIngredients(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des ingrédients", error);
            }
        };
    
        fetchIngredients();
    }, []);

    const handleAddIngredient = (ingredientId) => {
        console.log("Ajout de l'ingrédient:", ingredientId);  // Affiche l'ID de l'ingrédient ajouté
        if (!selectedIngredients.includes(ingredientId)) {
            setSelectedIngredients([...selectedIngredients, ingredientId]);
        }
    };

    const handleRemoveIngredient = (ingredientId) => {
        setSelectedIngredients(selectedIngredients.filter(id => id !== ingredientId));
    };

    const handleAddCategory = (categoryId) => {
        if (!selectedCategories.includes(categoryId)) {
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };

    const handleRemoveCategory = (categoryId) => {
        setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
    };



    const [formData, setFormData] = useState({
        nom: '',
        description: '',
        marque_id: 1,
        image: null,
        //... autres champs nécessaires
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData(prevState => ({
            ...prevState,
            image: file
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const dataToSend = {
        //     ...formData,
        //     categories: selectedCategories,
        //     ingredients: selectedIngredients
        // };

        const dataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            dataToSend.append(key, formData[key]);
        });
        dataToSend.append('categories', JSON.stringify(selectedCategories));
        dataToSend.append('ingredients', JSON.stringify(selectedIngredients));
        dataToSend.append('image', formData.image);

        // for (var pair of dataToSend.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }
        


        try {
        //     const response = await axios.post('https://backoffice.gourdefriendly.green/drinks/drinks', dataToSend, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // });

        const response = await axiosInstance.post('/drinks/drinks', dataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
            if (response.status === 201) {
                alert('Drink created successfully!');

                console.log(response)
                // Reset le formulaire
                // setFormData({
                //     nom: '',
                //     description: '',
                //     categories: [],
                //     marque_id: ''
                //     //... autres champs
                // });
            }
        } catch (error) {
            console.log(dataToSend)
            console.error("Erreur lors de la création de la boisson", error);
            alert('Erreur lors de la création de la boisson');
        }
    }


 
      
    

    return (
        <>
        <Navbar />
            <FormWrapper>

            <CategoryList />
            </FormWrapper>
   
       

        <FormWrapper>

            <button onClick={() => setCategoryModalOpen(true)} style={{ marginRight: '10px' }}>Ajouter une catégorie</button>
            <button onClick={() => setBrandModalOpen(true)} style={{ marginRight: '10px' }}>Ajouter une marque</button>
            <button onClick={() => setIngredientModalOpen(true)} style={{ marginRight: '10px' }}>
                Ajouter un ingrédient
            </button>

            <Modal isOpen={isCategoryModalOpen} onClose={() => setCategoryModalOpen(false)}>
                <CreateCategoryForm onClose={() => setCategoryModalOpen(false)} />
            </Modal>

            <Modal isOpen={isBrandModalOpen} onClose={() => setBrandModalOpen(false)}>
                <CreateBrandForm onClose={() => setBrandModalOpen(false)} />
            </Modal>
            <Modal isOpen={isIngredientModalOpen} onClose={() => setIngredientModalOpen(false)}>
                <CreateIngredientForm onClose={() => setIngredientModalOpen(false)} />
            </Modal>

            <Title>Créer une nouvelle boisson</Title>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Label>Nom:</Label>
                    <Input type="text" name="nom" value={formData.nom} onChange={handleChange} required />
                </FormField>
                <FormField>
    <Label>Image :</Label>
    <Input type="file" name="image" onChange={handleImageChange} />
</FormField>
                <FormField>
                    <Label>Description:</Label>
                    <Textarea name="description" value={formData.description} onChange={handleChange} required></Textarea>
                </FormField>
                <FormField>
                    <Label>Catégories :</Label>
                    <select name="category" id="category">
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={() => handleAddCategory(Number(document.getElementById('category').value))}>
                        Ajouter la catégorie
                    </button>
                </FormField>
                <div>
                    {selectedCategories.map(categoryId => {
                        const category = categories.find(cat => cat.id == categoryId);

                        return (
                            <IngredientCard key={categoryId}>
                                {category ? category.name : 'Catégorie non trouvée'}
                                <RemoveButton type="button" onClick={() => handleRemoveCategory(categoryId)}>✖</RemoveButton>
                            </IngredientCard>
                        );
                    })}
                </div>

                <FormField>
                    <Label>Marque :</Label>
                    <select name="marque_id" value={formData.marque_id} onChange={handleChange}>
                        <option value="">Aucune marque</option> {/* Ajoutez cette option */}
                        {brands.map(brand => (
                            <option key={brand.id} value={brand.id}>
                                {brand.name}
                            </option>
                        ))}
                    </select>
                </FormField>



                <FormField>
                    <Label>Ingrédients :</Label>
                    <select name="ingredient" id="ingredient">
                        {availableIngredients.map(ingredient => (
                            <option key={ingredient.id} value={ingredient.id}>
                                {ingredient.nom}
                            </option>
                        ))}
                    </select>
                    <button type="button" onClick={() => handleAddIngredient(document.getElementById('ingredient').value)}>
                        Ajouter l'ingrédient
                    </button>
                </FormField>
                <div>
                    {selectedIngredients.map(ingredientId => {
                        const ingredient = availableIngredients.find(ing => ing.id == ingredientId);

                        return (
                            <IngredientCard key={ingredientId}>
                                {ingredient ? ingredient.nom : 'Ingrédient non trouvé'}
                                <RemoveButton type="button" onClick={() => handleRemoveIngredient(ingredientId)}>✖</RemoveButton>
                            </IngredientCard>
                        );
                    })}
                </div>

                {/* ... autres champs nécessaires */}
                <FormField>
                    <SubmitButton type="submit">Ajouter la boisson</SubmitButton>
                </FormField>
            </form>


        </FormWrapper>


        <DrinksList  />


</>
    );
}

export default CreateDrinkForm;
