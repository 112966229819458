import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';
function CHRReport({ etabId }) {
    const [reportData, setReportData] = useState(null);

    // useEffect(() => {
    //     fetch(`https://backoffice.gourdefriendly.green/stock_mug/report/distributed/${etabId}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             setReportData(data);
    //         });
    // }, [etabId]);

    useEffect(() => {
        axiosInstance.get(`/stock_mug/report/distributed/${etabId}`)
            .then(response => {
                setReportData(response.data);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération du rapport distribué :", error);
            });
    }, [etabId]);

    return (
        <div>
            <h2>Rapport des mugs distribuées par {reportData?.etablissement_name}</h2>
            <p>Total distribué: {reportData?.total_distributed}</p>
        </div>
    );
}

export default CHRReport;
