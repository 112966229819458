import React from 'react';

const AccessDeniedMessage = ({ etablissementName }) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h2>Accès Refusé</h2>
            <p>Vous n'avez pas l'autorisation d'accéder à {etablissementName || "cet établissement"}.</p>
        </div>
    );
};

export default AccessDeniedMessage;
