import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';
function PeriodReport({ startDate, endDate }) {
    const [reportData, setReportData] = useState(null);

    // useEffect(() => {
    //     fetch(`https://backoffice.gourdefriendly.green/stock/report/distributed-period?start_date=${startDate}&end_date=${endDate}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             setReportData(data);
    //         });
    // }, [startDate, endDate]);

    useEffect(() => {
        axiosInstance.get(`/stock/report/distributed-period?start_date=${startDate}&end_date=${endDate}`)
            .then(response => {
                setReportData(response.data);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des données de rapport :", error);
            });
    }, [startDate, endDate]);

    return (
        <div>
            <h2>Rapport des gourdes distribuées du {startDate} au {endDate}</h2>
            <p>Total distribué: {reportData?.total_distributed}</p>
        </div>
    );
}

export default PeriodReport;
