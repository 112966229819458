import React, { useState, useEffect } from 'react';
import { Modal, Button, TextField, Card, CardContent, Grid, makeStyles, Typography ,} from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import axiosInstance from '../../services/axiosInstance';


const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  card: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.3)", // Add shadow effect
  },
  cardContent: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    padding: theme.spacing(2),
    alignItems: 'center',


  },
  cardText: {
    flex: '1 0 auto',
    marginBottom: theme.spacing(2),
  },
}));

function CHRPage() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [establishments, setEstablishments] = useState([]);

  

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  
  // useEffect(() => {
  //   const fetchEstablishments = async () => {

  //     const partnerUserId = localStorage.getItem('user_id') // Remplacez ceci par l'ID réel de l'utilisateur partenaire
  //     const response = await axios.get(`https://backoffice.gourdefriendly.green/partner_users/${partnerUserId}/get_establishments`);

   

  //     setEstablishments(response.data.establishment);
  //   };

  //   fetchEstablishments();
  // }, []);

  useEffect(() => {
    const fetchEstablishments = async () => {
      const partnerUserId = localStorage.getItem('user_id'); // Utilisez l'ID de l'utilisateur partenaire
      try {
        const response = await axiosInstance.get(`/partner_users/${partnerUserId}/get_establishments`);
        setEstablishments(response.data.establishment);
      } catch (error) {
        console.error('Erreur lors de la récupération des établissements', error);
      }
    };
  
    fetchEstablishments();
  }, []);

  // const handleAddEstablishment = async (newEstablishment) => {

  //   console.log(newEstablishment)
  
  //   const response = await axios.post('https://backoffice.gourdefriendly.green/partner_users/add_establishment', newEstablishment);

  //   setEstablishments([...establishments, response.data.establishment]);
  //   return response.data;
  // };


  const handleAddEstablishment = async (newEstablishment) => {
    try {


      const token = localStorage.getItem('token');
      if (token) {
        console.log(`Bearer ${token}`);
        console.log('https://backoffice.gourdefriendly.green/partner_users/add_establishment')
        console.log( newEstablishment)
      }

      const response = await axiosInstance.post('/partner_users/add_establishment', newEstablishment);
      setEstablishments([...establishments, response.data.establishment]);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l\'ajout d\'un établissement', error);
      return null; // ou gérer l'erreur d'une autre manière
    }
  };

  return (
    <div>
      <Navbar />
      <Button variant="contained" color="primary" onClick={handleOpen} style={{marginBottom: '2rem',margin : '2rem'}}>
        +
      </Button>
 
     <Grid container spacing={3}>
     {establishments.map((establishment, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" className={classes.cardText}>
                  {establishment.name}
                </Typography>
                <Button variant="contained" color="primary" component={Link} to={`/admin/${establishment.id}`}>
                  Gérer
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}

      </Grid>
      <EstablishmentModal open={open} onClose={handleClose} onAdd={handleAddEstablishment} />

    </div>
  );
}

function EstablishmentModal({ open, onClose, onAdd }) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
  // Add more fields as needed

  const partner_user_id = localStorage.getItem('user_id')

  const getCoordinates = async (address) => {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${address}`;
    const response = await axios.get(url);
    if (response.data && response.data[0]) {
      return {
        lat: parseFloat(response.data[0].lat),
        lon: parseFloat(response.data[0].lon)
      };
    }
    return null;
  };
  

  const handleAdd = async () => {
    const coordinates = await getCoordinates(address);
  if (!coordinates) {
    alert("Impossible d'obtenir les coordonnées pour cette adresse.");
    return;
  }
  
  const newEstablishment = {
    partner_user_id: localStorage.getItem('user_id'),
    name,
    address,
    lat: coordinates.lat,
    lon: coordinates.lon,
    email,
  phone,
    // ... (autres champs)
  };



  const response = await onAdd(newEstablishment);  // Modifiez cette ligne pour récupérer la réponse
  setName('');
  setAddress('');
  
  // Redirigez l'utilisateur vers l'URL Stripe pour ajouter ses informations
  if (response && response.account_link) {
      window.location.href = response.account_link;
  }
  };

  

  

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.modal}>
        <h2>Ajouter un établissement</h2>
        <TextField value={name} onChange={e => setName(e.target.value)} label="Nom" fullWidth/>
        <TextField value={address} onChange={e => setAddress(e.target.value)} label="Ville" fullWidth/>
        <TextField value={email} onChange={e => setEmail(e.target.value)} label="Email" fullWidth/>
        <TextField value={phone} onChange={e => setPhone(e.target.value)} label="Téléphone" fullWidth/>
        {/* Add more fields as needed */}
        <Button variant="contained" color="primary" onClick={handleAdd} style={{marginTop: '1rem'}}>
          Ajouter
        </Button>
      </div>
    </Modal>
  );
}

export default CHRPage;
