import React, { useState } from 'react';
import { TextField, Button, makeStyles, Grid, IconButton, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
  scheduleContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  timeSlot: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginRight: theme.spacing(2),
    width: '150px',
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  dayCheckbox: {
    marginRight: theme.spacing(1),
  },
}));

const joursDeLaSemaine = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

function DrinkSchedule({ onScheduleChange }) {
  const classes = useStyles();
  const [schedule, setSchedule] = useState([{ ouverture: "", fermeture: "", jours: [] }]);


  const handleTimeChange = (index, field, value) => {
    const newSchedule = [...schedule];
    newSchedule[index][field] = value;
    setSchedule(newSchedule);
    onScheduleChange(newSchedule);
  };

  const handleDayChange = (index, jour) => {
    const newSchedule = [...schedule];
    const jours = newSchedule[index].jours;
    if (jours.includes(jour)) {
      newSchedule[index].jours = jours.filter(j => j !== jour);
    } else {
      newSchedule[index].jours = [...jours, jour];
    }
    setSchedule(newSchedule);
    onScheduleChange(newSchedule);
  };

  const addTimeSlot = () => {
    setSchedule([...schedule, { ouverture: "", fermeture: "", jours: [] }]);
  };

  const removeTimeSlot = (index) => {
    const newSchedule = schedule.filter((_, i) => i !== index);
    setSchedule(newSchedule);
    onScheduleChange(newSchedule);
  };

  return (
    <div className={classes.scheduleContainer}>
      {schedule.map((slot, index) => (
        <Grid container alignItems="center" key={index} className={classes.timeSlot}>
          <Grid item>
            <TextField
              label="Début de service"
              type="time"
              value={slot.ouverture}
              onChange={(e) => handleTimeChange(index, 'ouverture', e.target.value)}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Fin de service"
              type="time"
              value={slot.fermeture}
              onChange={(e) => handleTimeChange(index, 'fermeture', e.target.value)}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              {joursDeLaSemaine.map(jour => (
                <FormControlLabel
                  key={jour}
                  control={
                    <Checkbox
                      checked={slot.jours.includes(jour)}
                      onChange={() => handleDayChange(index, jour)}
                      className={classes.dayCheckbox}
                    />
                  }
                  label={jour}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => removeTimeSlot(index)}
              color="secondary"
              className={classes.iconButton}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={addTimeSlot}
        className={classes.addButton}
      >
        Ajouter un créneau horaire
      </Button>
    </div>
  );
}

export default DrinkSchedule;
